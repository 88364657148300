/**
 * This file is generated by the generate-components script. Do not edit directly.
 */
/* eslint-disable */
import { createIcon } from "../createIcon.tsx";
import { forwardRef, type Ref, type SVGProps } from "react";

const ProfileIcon16 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => <svg width={16} height={16} viewBox="0 0 16 16" ref={ref} {...props}><path fillRule="evenodd" d="M1 8a7 7 0 1 1 14 0A7 7 0 0 1 1 8m7-5.5a5.5 5.5 0 0 0-3.817 9.46 5.8 5.8 0 0 1 1.326-1.723q.277-.24.578-.426a3 3 0 1 1 3.826 0q.301.186.578.426a5.8 5.8 0 0 1 1.326 1.723A5.5 5.5 0 0 0 8 2.5m2.587 10.355c-.295-.627-.668-1.129-1.078-1.484-.493-.427-1.013-.621-1.509-.621s-1.016.194-1.509.62c-.41.356-.783.858-1.078 1.485A5.5 5.5 0 0 0 8 13.5c.935 0 1.816-.233 2.587-.645M8 6a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3" /></svg>);
const ProfileIcon24 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => <svg width={24} height={24} viewBox="0 0 24 24" ref={ref} {...props}><path fillRule="evenodd" d="M3 12a9 9 0 1 1 18 0 9 9 0 0 1-18 0m9-7.5a7.5 7.5 0 0 0-5.477 12.624c.532-.958 1.216-1.777 2.02-2.396q.484-.373 1.01-.637a3.75 3.75 0 1 1 4.895 0q.526.265 1.01.636c.804.62 1.487 1.44 2.019 2.397A7.5 7.5 0 0 0 12 4.5m4.315 13.635c-.466-.927-1.076-1.683-1.772-2.219C13.749 15.305 12.873 15 12 15c-.872 0-1.748.305-2.542.916-.697.536-1.307 1.292-1.773 2.22A7.47 7.47 0 0 0 12 19.5a7.47 7.47 0 0 0 4.315-1.365M12 9a2.25 2.25 0 1 0 0 4.5A2.25 2.25 0 0 0 12 9" /></svg>);
const ProfileIcon32 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => <svg width={32} height={32} viewBox="0 0 32 32" ref={ref} {...props}><path fillRule="evenodd" d="M4 16C4 9.373 9.373 4 16 4s12 5.373 12 12-5.373 12-12 12S4 22.627 4 16M16 6C10.477 6 6 10.477 6 16a9.97 9.97 0 0 0 2.84 6.982 8.85 8.85 0 0 1 2.24-3.08 8 8 0 0 1 1.652-1.118 5 5 0 1 1 6.537 0 8 8 0 0 1 1.652 1.117 8.9 8.9 0 0 1 2.239 3.08A9.97 9.97 0 0 0 26 16c0-5.523-4.477-10-10-10m5.543 18.324c-.413-1.162-1.086-2.169-1.941-2.919C18.551 20.483 17.284 20 16 20s-2.55.483-3.602 1.405c-.855.75-1.528 1.757-1.941 2.92A9.95 9.95 0 0 0 16 26a9.95 9.95 0 0 0 5.543-1.676M16 12a3 3 0 1 0 0 6 3 3 0 0 0 0-6" /></svg>);

export const ProfileIcon = createIcon(ProfileIcon16, ProfileIcon24, ProfileIcon32, "ProfileIcon");