/**
 * This file is generated by the generate-components script. Do not edit directly.
 */
/* eslint-disable */
import { createIcon } from "../createIcon.tsx";
import { forwardRef, type Ref, type SVGProps } from "react";

const FilterIcon16 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => <svg width={16} height={16} viewBox="0 0 16 16" ref={ref} {...props}><path fillRule="evenodd" d="M7.25 5.25a.75.75 0 0 0 1.5 0V4.5h4.5a.75.75 0 0 0 0-1.5h-4.5v-.75a.75.75 0 0 0-1.5 0V3h-4.5a.75.75 0 0 0 0 1.5h4.5zm-4.5 6.25a.75.75 0 0 0 0 1.5H4v.75a.75.75 0 0 0 1.5 0V13h7.75a.75.75 0 0 0 0-1.5H5.5v-.75a.75.75 0 0 0-1.5 0v.75zM2 8a.75.75 0 0 1 .75-.75h7.75V6.5a.75.75 0 0 1 1.5 0v.75h1.25a.75.75 0 0 1 0 1.5H12v.75a.75.75 0 0 1-1.5 0v-.75H2.75A.75.75 0 0 1 2 8" /></svg>);
const FilterIcon24 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => <svg width={24} height={24} viewBox="0 0 24 24" ref={ref} {...props}><path fillRule="evenodd" d="M11.25 8.25a.75.75 0 0 0 1.5 0V6.5h7.502a.75.75 0 0 0 0-1.5H12.75V3.25a.75.75 0 0 0-1.5 0V5H3.748a.75.75 0 0 0 0 1.5h7.502zm-7.502 3a.75.75 0 0 0 0 1.5H16.5v1.75a.75.75 0 0 0 1.5 0v-1.75h2.251a.75.75 0 0 0 0-1.5H18V9.5a.75.75 0 0 0-1.5 0v1.75zm-.75 7a.75.75 0 0 1 .75-.75H6v-1.75a.75.75 0 0 1 1.5 0v1.75h12.752a.75.75 0 0 1 0 1.5H7.5v1.75a.75.75 0 0 1-1.5 0V19H3.748a.75.75 0 0 1-.75-.75" /></svg>);
const FilterIcon32 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => <svg width={32} height={32} viewBox="0 0 32 32" ref={ref} {...props}><path fillRule="evenodd" d="M15 9.75a1 1 0 1 0 2 0V8h10a1 1 0 1 0 0-2H17V4.25a1 1 0 1 0-2 0V6H5a1 1 0 0 0 0 2h10zM5 15a1 1 0 1 0 0 2h18v1.75a1 1 0 1 0 2 0V17h2a1 1 0 1 0 0-2h-2v-1.75a1 1 0 1 0-2 0V15zM4 25a1 1 0 0 1 1-1h2v-1.75a1 1 0 1 1 2 0V24h18a1 1 0 1 1 0 2H9v1.75a1 1 0 1 1-2 0V26H5a1 1 0 0 1-1-1" /></svg>);

export const FilterIcon = createIcon(FilterIcon16, FilterIcon24, FilterIcon32, "FilterIcon");