/**
 * This file is generated by the generate-components script. Do not edit directly.
 */
/* eslint-disable */
import { createIcon } from "../createIcon.tsx";
import { forwardRef, type Ref, type SVGProps } from "react";

const RewindIcon16 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => <svg width={16} height={16} viewBox="0 0 16 16" ref={ref} {...props}><path fillRule="evenodd" d="M8.215 2.715A5.285 5.285 0 0 0 2.929 8v.086l.153-.123a.75.75 0 0 1 .937 1.171l-1.371 1.098a.75.75 0 0 1-.937 0L.339 9.134a.75.75 0 0 1 .937-1.171l.153.123V8a6.785 6.785 0 1 1 6.786 6.785.75.75 0 0 1 0-1.5 5.285 5.285 0 1 0 0-10.57" /></svg>);
const RewindIcon24 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => <svg width={24} height={24} viewBox="0 0 24 24" ref={ref} {...props}><path fillRule="evenodd" d="m4.519 11.468.7-.701a.75.75 0 0 1 1.061 1.06l-2 2a.75.75 0 0 1-1.06 0l-2-2a.75.75 0 0 1 1.06-1.06l.734.733A9 9 0 1 1 12 21a.75.75 0 0 1 0-1.5 7.5 7.5 0 1 0-7.481-8.032" /></svg>);
const RewindIcon32 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => <svg width={32} height={32} viewBox="0 0 32 32" ref={ref} {...props}><path fillRule="evenodd" d="m6.009 15.577 1.284-1.284a1 1 0 0 1 1.414 1.414l-3 3a1 1 0 0 1-1.414 0l-3-3a1 1 0 1 1 1.414-1.414l1.3 1.3C4.222 9.153 9.509 4 16 4c6.628 0 12 5.373 12 12s-5.372 12-12 12a1 1 0 0 1 0-2c5.523 0 10-4.477 10-10S21.523 6 16 6c-5.381 0-9.77 4.25-9.991 9.577" /></svg>);

export const RewindIcon = createIcon(RewindIcon16, RewindIcon24, RewindIcon32, "RewindIcon");