/**
 * This file is generated by the generate-components script. Do not edit directly.
 */
/* eslint-disable */
import { createIcon } from "../createIcon.tsx";
import { forwardRef, type Ref, type SVGProps } from "react";

const ItalicIcon16 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => <svg width={16} height={16} viewBox="0 0 16 16" ref={ref} {...props}><path fillRule="evenodd" d="M6.682 1.751a.75.75 0 0 1 .75-.75h4.545a.75.75 0 0 1 0 1.5h-1.7l-3 10.998h1.291a.75.75 0 0 1 0 1.5H4.024a.75.75 0 0 1 0-1.5h1.699l3-10.998H7.431a.75.75 0 0 1-.75-.75" /></svg>);
const ItalicIcon24 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => <svg width={24} height={24} viewBox="0 0 24 24" ref={ref} {...props}><path fillRule="evenodd" d="M11.25 3.75A.75.75 0 0 1 12 3h6a.75.75 0 0 1 0 1.5h-2.528l-5.351 15h1.986a.75.75 0 0 1 0 1.5h-6a.75.75 0 0 1 0-1.5h2.421l5.351-15H12a.75.75 0 0 1-.75-.75" /></svg>);
const ItalicIcon32 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => <svg width={32} height={32} viewBox="0 0 32 32" ref={ref} {...props}><path fillRule="evenodd" d="M14.755 5a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2h-3.131L13.49 26h2.581a1 1 0 1 1 0 2h-8a1 1 0 1 1 0-2h3.295L18.5 6h-2.745a1 1 0 0 1-1-1" /></svg>);

export const ItalicIcon = createIcon(ItalicIcon16, ItalicIcon24, ItalicIcon32, "ItalicIcon");