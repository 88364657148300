/**
 * This file is generated by the generate-components script. Do not edit directly.
 */
/* eslint-disable */
import { createIcon } from "../createIcon.tsx";
import { forwardRef, type Ref, type SVGProps } from "react";

const CheckmarkIcon16 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => <svg width={16} height={16} viewBox="0 0 16 16" ref={ref} {...props}><path fillRule="evenodd" d="M13.768 3.892c.3.285.313.76.028 1.06L7.044 12.08a.75.75 0 0 1-1.075.014L2.218 8.343a.75.75 0 0 1 1.06-1.06l3.207 3.206 6.222-6.568a.75.75 0 0 1 1.06-.029" /></svg>);
const CheckmarkIcon24 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => <svg width={24} height={24} viewBox="0 0 24 24" ref={ref} {...props}><path fillRule="evenodd" d="M19.766 6.536c.3.285.313.76.028 1.06l-9.321 9.84a.75.75 0 0 1-1.075.014L4.22 12.272a.75.75 0 1 1 1.06-1.061l4.634 4.634 8.791-9.28a.75.75 0 0 1 1.06-.029" /></svg>);
const CheckmarkIcon32 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => <svg width={32} height={32} viewBox="0 0 32 32" ref={ref} {...props}><path fillRule="evenodd" d="M25.688 9.167a1 1 0 0 1 .038 1.414L14.155 22.795a1 1 0 0 1-1.434.02l-6.428-6.43a1 1 0 0 1 1.414-1.413l5.702 5.702L24.274 9.205a1 1 0 0 1 1.414-.038" /></svg>);

export const CheckmarkIcon = createIcon(CheckmarkIcon16, CheckmarkIcon24, CheckmarkIcon32, "CheckmarkIcon");