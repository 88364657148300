/**
 * This file is generated by the generate-components script. Do not edit directly.
 */
/* eslint-disable */
import { createIcon } from "../createIcon.tsx";
import { forwardRef, type Ref, type SVGProps } from "react";

const FocusIcon16 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => <svg width={16} height={16} viewBox="0 0 16 16" ref={ref} {...props}><path fillRule="evenodd" d="M5.25 2.5v5.25a2.75 2.75 0 0 0 5.5 0V2.5zM4.75 1a1 1 0 0 0-1 1v2h-.707a1.75 1.75 0 0 0-1.749 1.823l.008.184a3.646 3.646 0 0 0 2.78 3.39 4.26 4.26 0 0 0 3.168 2.537V13.5h-1.5a.75.75 0 0 0 0 1.5h4.5a.75.75 0 0 0 0-1.5h-1.5v-1.566a4.26 4.26 0 0 0 3.167-2.532 3.646 3.646 0 0 0 2.781-3.39l.008-.19A1.75 1.75 0 0 0 12.958 4h-.708V2a1 1 0 0 0-1-1zm-1 6.638V5.5h-.707a.25.25 0 0 0-.25.26l.007.185c.03.708.4 1.324.95 1.693m9.45-1.69c-.03.71-.4 1.325-.95 1.694V5.5h.707a.25.25 0 0 1 .25.26z" /></svg>);
const FocusIcon24 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => <svg width={24} height={24} viewBox="0 0 24 24" ref={ref} {...props}><path fillRule="evenodd" d="M8 7.5V11a3.5 3.5 0 0 0 3.5 3.5h1A3.5 3.5 0 0 0 16 11V4.5H8zm-1.5 0v3.371A2.25 2.25 0 0 1 5.5 9V7.75a.25.25 0 0 1 .25-.25zm0-1.5h-.75A1.75 1.75 0 0 0 4 7.75V9a3.75 3.75 0 0 0 2.768 3.62 5 5 0 0 0 4.482 3.374V17H9.995A2.995 2.995 0 0 0 7 19.995c0 .55.447.998.998.998h8.004a1 1 0 0 0 .998-.998A2.995 2.995 0 0 0 14.005 17H12.75v-1.006a5 5 0 0 0 4.482-3.374A3.75 3.75 0 0 0 20 9V7.75A1.75 1.75 0 0 0 18.25 6h-.75V4a1 1 0 0 0-1-1h-9a1 1 0 0 0-1 1zm11 4.871V7.5h.75a.25.25 0 0 1 .25.25V9c0 .78-.397 1.467-1 1.871M14.005 18.5h-4.01c-.65 0-1.203.414-1.409.993h6.828a1.495 1.495 0 0 0-1.409-.993" /></svg>);
const FocusIcon32 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => <svg width={32} height={32} viewBox="0 0 32 32" ref={ref} {...props}><path fillRule="evenodd" d="M11 6v8a5 5 0 0 0 10 0V6zm-1-2a1 1 0 0 0-1 1v3H7a2 2 0 0 0-2 2v3a4 4 0 0 0 4 4h.674A7.01 7.01 0 0 0 15 20.93V23h-1a4 4 0 0 0-4 4 1 1 0 0 0 1 1h10a1 1 0 0 0 1-1 4 4 0 0 0-4-4h-1v-2.07A7.01 7.01 0 0 0 22.326 17H23a4 4 0 0 0 4-4v-3a2 2 0 0 0-2-2h-2V5a1 1 0 0 0-1-1zm13 6v4q0 .51-.07 1H23a2 2 0 0 0 2-2v-3zM9 14v-4H7v3a2 2 0 0 0 2 2h.07A7 7 0 0 1 9 14m5 11a2 2 0 0 0-1.732 1h7.465A2 2 0 0 0 18 25z" /></svg>);

export const FocusIcon = createIcon(FocusIcon16, FocusIcon24, FocusIcon32, "FocusIcon");