/**
 * This file is generated by the generate-components script. Do not edit directly.
 */
/* eslint-disable */
import { createIcon } from "../createIcon.tsx";
import { forwardRef, type Ref, type SVGProps } from "react";

const CalendarIcon16 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => <svg width={16} height={16} viewBox="0 0 16 16" ref={ref} {...props}><path fillRule="evenodd" d="M6.5 1.75a.75.75 0 0 0-1.5 0V2H3a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1h-2v-.25a.75.75 0 1 0-1.5 0V2h-3zM10.25 4a.75.75 0 0 1-.707-.5H6.457a.75.75 0 0 1-1.414 0H3.5v2h9v-2h-1.542a.75.75 0 0 1-.708.5m2.25 3h-9v5.5h9z" /></svg>);
const CalendarIcon24 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => <svg width={24} height={24} viewBox="0 0 24 24" ref={ref} {...props}><path fillRule="evenodd" d="M8.503 3.75a.75.75 0 0 0-1.5 0V4H5.5A1.5 1.5 0 0 0 4 5.5v13A1.5 1.5 0 0 0 5.5 20h13a1.5 1.5 0 0 0 1.5-1.5v-13A1.5 1.5 0 0 0 18.5 4H17v-.25a.75.75 0 0 0-1.5 0V4H8.503zm6.997 2.5V5.5H8.503v.75a.75.75 0 0 1-1.5 0V5.5H5.5V8h13V5.5H17v.75a.75.75 0 0 1-1.5 0m3 3.25h-13v9h13z" /></svg>);
const CalendarIcon32 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => <svg width={32} height={32} viewBox="0 0 32 32" ref={ref} {...props}><path fillRule="evenodd" d="M12 5a1 1 0 1 0-2 0v1H8a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2h-2V5a1 1 0 1 0-2 0v1h-8zm12 7V8h-2v1a1 1 0 1 1-2 0V8h-8v1a1 1 0 1 1-2 0V8H8v4zM8 14v10h16V14z" /></svg>);

export const CalendarIcon = createIcon(CalendarIcon16, CalendarIcon24, CalendarIcon32, "CalendarIcon");