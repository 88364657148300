/**
 * This file is generated by the generate-components script. Do not edit directly.
 */
/* eslint-disable */
import { createIcon } from "../createIcon.tsx";
import { forwardRef, type Ref, type SVGProps } from "react";

const RecurringIcon16 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => <svg width={16} height={16} viewBox="0 0 16 16" ref={ref} {...props}><path fillRule="evenodd" d="M12.336 3.116a.75.75 0 1 1 1.06-1.061l1.272 1.271a1.083 1.083 0 0 1 0 1.532L13.397 6.13a.75.75 0 0 1-1.06-1.06l.226-.227h-7.15A1.917 1.917 0 0 0 3.497 6.76v1.1a.75.75 0 0 1-1.5 0v-1.1a3.417 3.417 0 0 1 3.416-3.417h7.15zm1.66 6.318a3.417 3.417 0 0 1-3.418 3.416h-7.14l.226.227a.75.75 0 0 1-1.06 1.06l-1.272-1.27a1.083 1.083 0 0 1 0-1.533l1.271-1.27a.75.75 0 1 1 1.061 1.06l-.226.226h7.14a1.917 1.917 0 0 0 1.917-1.916v-1.1a.75.75 0 0 1 1.5 0z" /></svg>);
const RecurringIcon24 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => <svg width={24} height={24} viewBox="0 0 24 24" ref={ref} {...props}><path fillRule="evenodd" d="M17.818 6.282a.75.75 0 0 1 1.06-1.06l1.808 1.807a1.083 1.083 0 0 1 0 1.533l-1.808 1.807a.75.75 0 0 1-1.06-1.06l.763-.764H7.417A1.917 1.917 0 0 0 5.5 10.462v.788a.75.75 0 0 1-1.5 0v-.788a3.417 3.417 0 0 1 3.417-3.417H18.58zm1.853 7.263a3.417 3.417 0 0 1-3.416 3.417H5.427l.763.763a.75.75 0 0 1-1.06 1.06l-1.808-1.807a1.083 1.083 0 0 1 0-1.532l1.807-1.808a.75.75 0 1 1 1.061 1.06l-.763.764h10.828a1.917 1.917 0 0 0 1.916-1.917v-.79a.75.75 0 0 1 1.5 0z" /></svg>);
const RecurringIcon32 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => <svg width={32} height={32} viewBox="0 0 32 32" ref={ref} {...props}><path fillRule="evenodd" d="M23.667 7.694a1 1 0 1 1 1.44-1.388l2.39 2.48a1.333 1.333 0 0 1 0 1.85l-2.39 2.48a1 1 0 1 1-1.44-1.389l.98-1.016H9.667c-.92 0-1.667.746-1.667 1.666v2.626a1 1 0 0 1-2 0v-2.626a3.667 3.667 0 0 1 3.667-3.666h14.98zM26 19.623a3.667 3.667 0 0 1-3.667 3.666H7.39l.994 1.01a1 1 0 0 1-1.425 1.403l-2.44-2.477a1.333 1.333 0 0 1 0-1.871l2.44-2.477a1 1 0 1 1 1.425 1.404l-.994 1.008h14.944c.92 0 1.667-.746 1.667-1.666V17a1 1 0 1 1 2 0z" /></svg>);

export const RecurringIcon = createIcon(RecurringIcon16, RecurringIcon24, RecurringIcon32, "RecurringIcon");