/**
 * This file is generated by the generate-components script. Do not edit directly.
 */
/* eslint-disable */
import { createIcon } from "../createIcon.tsx";
import { forwardRef, type Ref, type SVGProps } from "react";

const ThumbsUpIcon16 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => <svg width={16} height={16} viewBox="0 0 16 16" ref={ref} {...props}><path fillRule="evenodd" d="M8.257 1.435c.76-.869 2.19-.331 2.19.823V4.54h2.519a2.04 2.04 0 0 1 1.538.683 2 2 0 0 1 .476 1.619v.004l-.876 5.458v.003a2 2 0 0 1-.693 1.223c-.37.31-.84.477-1.322.473H3.025A2.03 2.03 0 0 1 1.6 13.42a2 2 0 0 1-.596-1.418V7.627c0-.534.215-1.044.596-1.418a2.03 2.03 0 0 1 1.424-.582h1.564zM4.18 7.127H3.025a.53.53 0 0 0-.372.15.5.5 0 0 0-.148.35v4.375a.5.5 0 0 0 .148.349.53.53 0 0 0 .372.15H4.18zm1.5 5.375h6.42a.53.53 0 0 0 .348-.123.5.5 0 0 0 .173-.304v-.005l.876-5.458v-.001a.48.48 0 0 0-.118-.396.52.52 0 0 0-.402-.176h-2.78c-.69 0-1.25-.56-1.25-1.25V2.924L5.68 6.659z" /></svg>);
const ThumbsUpIcon24 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => <svg width={24} height={24} viewBox="0 0 24 24" ref={ref} {...props}><path fillRule="evenodd" d="M12.37 2.971c.911-1.042 2.629-.397 2.629.988v3.547h3.552a2.45 2.45 0 0 1 1.85.82 2.4 2.4 0 0 1 .571 1.942l-.002.013-1.156 6.688a2.4 2.4 0 0 1-.83 1.463 2.44 2.44 0 0 1-1.59.568H5.427a2.44 2.44 0 0 1-1.712-.699A2.4 2.4 0 0 1 3 16.6v-5.775c0-.64.259-1.252.715-1.701a2.44 2.44 0 0 1 1.712-.7H7.6zM7.191 9.925H5.427a.94.94 0 0 0-.66.268.9.9 0 0 0-.267.632V16.6c0 .235.095.462.267.632a.94.94 0 0 0 .66.268h1.765zm1.5 7.575h8.714a.94.94 0 0 0 .615-.218.9.9 0 0 0 .312-.549l.002-.013 1.156-6.686a.88.88 0 0 0-.215-.714.95.95 0 0 0-.714-.314H15a1.5 1.5 0 0 1-1.5-1.5V3.96L8.69 9.457z" /></svg>);
const ThumbsUpIcon32 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => <svg width={32} height={32} viewBox="0 0 32 32" ref={ref} {...props}><path fillRule="evenodd" d="M16.493 4.629c1.215-1.39 3.505-.531 3.505 1.316V11h4.736a3.24 3.24 0 0 1 2.467 1.095 3.19 3.19 0 0 1 .762 2.588l-.004.024-1.54 8.59a3.2 3.2 0 0 1-1.108 1.947 3.26 3.26 0 0 1-2.12.757H7.236a3.25 3.25 0 0 1-2.282-.932A3.18 3.18 0 0 1 4 22.8v-7.7c0-.854.345-1.67.953-2.268a3.25 3.25 0 0 1 2.282-.932h2.9zm-6.904 9.27H7.235c-.332 0-.648.131-.879.358S6 14.787 6 15.1v7.7c0 .313.126.616.356.843s.547.357.88.357h2.353zm2 10.1h11.619c.302.004.594-.1.82-.29s.372-.45.416-.731l.004-.025 1.54-8.586a1.16 1.16 0 0 0-.286-.95 1.2 1.2 0 0 0-.426-.31 1.3 1.3 0 0 0-.526-.108h-4.752a2 2 0 0 1-2-2V5.945l-6.41 7.33z" /></svg>);

export const ThumbsUpIcon = createIcon(ThumbsUpIcon16, ThumbsUpIcon24, ThumbsUpIcon32, "ThumbsUpIcon");