/**
 * This file is generated by the generate-components script. Do not edit directly.
 */
/* eslint-disable */
import { createIcon } from "../createIcon.tsx";
import { forwardRef, type Ref, type SVGProps } from "react";

const LightbulbIcon16 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => <svg width={16} height={16} viewBox="0 0 16 16" ref={ref} {...props}><path fillRule="evenodd" d="M8 2.504a2.873 2.873 0 0 0-1.349 5.41.75.75 0 0 1 .397.662v.921h1.904v-.92a.75.75 0 0 1 .397-.662A2.873 2.873 0 0 0 8 2.504M3.627 5.377a4.373 4.373 0 1 1 6.825 3.622v1.248a.75.75 0 0 1-.75.75H6.298a.75.75 0 0 1-.75-.75V8.999a4.37 4.37 0 0 1-1.921-3.622m1.921 6.886a.75.75 0 0 1 .75-.75h3.404a.75.75 0 1 1 0 1.5H6.298a.75.75 0 0 1-.75-.75m1.318 1.219a.75.75 0 0 0 0 1.5h2.269a.75.75 0 1 0 0-1.5z" /></svg>);
const LightbulbIcon24 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => <svg width={24} height={24} viewBox="0 0 24 24" ref={ref} {...props}><path fillRule="evenodd" d="M12 4.5a4.25 4.25 0 0 0-1.912 8.047.75.75 0 0 1 .412.67v1.806h3v-1.807a.75.75 0 0 1 .412-.67A4.25 4.25 0 0 0 12 4.5M6.25 8.75A5.75 5.75 0 1 1 15 13.656v2.117a.75.75 0 0 1-.75.75h-4.5a.75.75 0 0 1-.75-.75v-2.117A5.75 5.75 0 0 1 6.25 8.75M9 18a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 0 1.5h-4.5A.75.75 0 0 1 9 18m1.75 1.5a.75.75 0 0 0 0 1.5h2.5a.75.75 0 0 0 0-1.5z" /></svg>);
const LightbulbIcon32 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => <svg width={32} height={32} viewBox="0 0 32 32" ref={ref} {...props}><path fillRule="evenodd" d="M16 6a6 6 0 0 0-2.57 11.423 1 1 0 0 1 .57.903V20h4v-1.674a1 1 0 0 1 .57-.903A6 6 0 0 0 16 6m-8 6a8 8 0 1 1 12 6.93V21a1 1 0 0 1-1 1h-6a1 1 0 0 1-1-1v-2.07A8 8 0 0 1 8 12m4 12a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2h-6a1 1 0 0 1-1-1m2 2a1 1 0 1 0 0 2h4a1 1 0 0 0 0-2z" /></svg>);

export const LightbulbIcon = createIcon(LightbulbIcon16, LightbulbIcon24, LightbulbIcon32, "LightbulbIcon");