/**
 * This file is generated by the generate-components script. Do not edit directly.
 */
/* eslint-disable */
import { createIcon } from "../createIcon.tsx";
import { forwardRef, type Ref, type SVGProps } from "react";

const LockIcon16 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => <svg width={16} height={16} viewBox="0 0 16 16" ref={ref} {...props}><path fillRule="evenodd" d="M8 1.003a3.92 3.92 0 0 0-3.919 3.919v.86h-1.08a1 1 0 0 0-1 1V14a1 1 0 0 0 1 1H13a1 1 0 0 0 1-1V6.781a1 1 0 0 0-1-1h-1.081v-.86A3.92 3.92 0 0 0 8 1.004m2.419 4.778v-.86a2.419 2.419 0 0 0-4.838 0v.86zM3.5 13.5V7.281h9V13.5z" /></svg>);
const LockIcon24 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => <svg width={24} height={24} viewBox="0 0 24 24" ref={ref} {...props}><path fillRule="evenodd" d="M12 3a4.6 4.6 0 0 0-4.6 4.6v2.2h-.9A1.5 1.5 0 0 0 5 11.3v8.2A1.5 1.5 0 0 0 6.5 21h11a1.5 1.5 0 0 0 1.5-1.5v-8.2a1.5 1.5 0 0 0-1.5-1.5h-.9V7.6A4.6 4.6 0 0 0 12 3m3.1 6.8V7.6a3.1 3.1 0 1 0-6.2 0v2.2zm-8.6 1.5h11v8.2h-11zm6.055 3.932a1 1 0 1 0-1.11 0l-.927 1.39a.5.5 0 0 0 .416.778h2.132a.5.5 0 0 0 .416-.777z" /></svg>);
const LockIcon32 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => <svg width={32} height={32} viewBox="0 0 32 32" ref={ref} {...props}><path fillRule="evenodd" d="M16 4a6.5 6.5 0 0 0-6.5 6.5v1.918H8a2 2 0 0 0-2 2V26a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V14.418a2 2 0 0 0-2-2h-1.5V10.5A6.5 6.5 0 0 0 16 4m4.5 8.418V10.5a4.5 4.5 0 1 0-9 0v1.918zm-12.5 2h16V26H8zm8.793 5.557c.383-.25.636-.675.636-1.158 0-.768-.64-1.391-1.429-1.391s-1.428.623-1.428 1.391c0 .483.252.909.636 1.158l-1.325 1.935c-.316.462.024 1.081.595 1.081h3.045c.57 0 .91-.619.594-1.081z" /></svg>);

export const LockIcon = createIcon(LockIcon16, LockIcon24, LockIcon32, "LockIcon");