/**
 * This file is generated by the generate-components script. Do not edit directly.
 */
/* eslint-disable */
import { createIcon } from "../createIcon.tsx";
import { forwardRef, type Ref, type SVGProps } from "react";

const SearchIcon16 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => <svg width={16} height={16} viewBox="0 0 16 16" ref={ref} {...props}><path fillRule="evenodd" d="M3.498 6.923a3.421 3.421 0 1 1 6.843 0 3.421 3.421 0 0 1-6.843 0M6.92 2.002a4.921 4.921 0 1 0 2.91 8.89l2.817 2.818a.75.75 0 0 0 1.06-1.06L10.89 9.832a4.921 4.921 0 0 0-3.97-7.83" /></svg>);
const SearchIcon24 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => <svg width={24} height={24} viewBox="0 0 24 24" ref={ref} {...props}><path fillRule="evenodd" d="M5.497 10.345a4.848 4.848 0 1 1 9.695 0 4.848 4.848 0 0 1-9.695 0m4.848-6.347a6.348 6.348 0 1 0 3.927 11.334l.002.002 4.441 4.441a.75.75 0 0 0 1.06-1.06l-4.44-4.441-.002-.002a6.348 6.348 0 0 0-4.988-10.274" /></svg>);
const SearchIcon32 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => <svg width={32} height={32} viewBox="0 0 32 32" ref={ref} {...props}><path fillRule="evenodd" d="M8 14a6 6 0 1 1 12 0 6 6 0 0 1-12 0m6-8a8 8 0 1 0 4.906 14.32l5.387 5.386a1 1 0 0 0 1.414-1.414l-5.387-5.387A8 8 0 0 0 14 6" /></svg>);

export const SearchIcon = createIcon(SearchIcon16, SearchIcon24, SearchIcon32, "SearchIcon");