/**
 * This file is generated by the generate-components script. Do not edit directly.
 */
/* eslint-disable */
import { createIcon } from "../createIcon.tsx";
import { forwardRef, type Ref, type SVGProps } from "react";

const TeamIcon16 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => <svg width={16} height={16} viewBox="0 0 16 16" ref={ref} {...props}><path fillRule="evenodd" d="M7.5 4.952a2 2 0 1 1-4 0 2 2 0 0 1 4 0m1.5 0a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0m1.5 2.625a1 1 0 1 1 2 0 1 1 0 0 1-2 0m1-2.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5m-1.574 7.708h3.22a1.44 1.44 0 0 0-1.093-.5h-1.106c-.257 0-.494.066-.7.18a1.5 1.5 0 0 1-.36.143q.021.087.039.177m-.663 1.5h5.184a.553.553 0 0 0 .553-.553 2.947 2.947 0 0 0-2.947-2.947h-1.106c-.518 0-1.004.133-1.427.368a3.92 3.92 0 0 0-3.116-1.535H4.93A3.93 3.93 0 0 0 1 13.548c0 .407.33.737.737.737zm-.885-1.5a2.43 2.43 0 0 0-2.308-1.667H4.93a2.43 2.43 0 0 0-2.308 1.667z" /></svg>);
const TeamIcon24 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => <svg width={24} height={24} viewBox="0 0 24 24" ref={ref} {...props}><path fillRule="evenodd" d="M6.605 7.441a1.95 1.95 0 0 1 1.958-1.943 1.95 1.95 0 0 1 1.958 1.943 1.95 1.95 0 0 1-1.958 1.944A1.95 1.95 0 0 1 6.605 7.44m1.958-3.443A3.45 3.45 0 0 0 5.105 7.44a3.45 3.45 0 0 0 3.458 3.444 3.45 3.45 0 0 0 3.458-3.444 3.45 3.45 0 0 0-3.458-3.443M7 14.098h3.114c.726 0 1.38.309 1.836.803.05.164.155.312.31.412.225.375.354.815.354 1.284v.9h-1.378l-.063.003H4.5v-.903a2.5 2.5 0 0 1 2.5-2.5m3.114-1.5c1 0 1.914.366 2.615.972a2.2 2.2 0 0 1 .761-.258c.433-.07 1.01-.064 1.728-.057l.529.004h1.008a4.25 4.25 0 0 1 4.25 4.25v.238c0 .69-.56 1.25-1.25 1.25h-6.328l-.063.003H4a1 1 0 0 1-1-1v-1.403a4 4 0 0 1 4-4zm4 4c0-.647-.153-1.258-.426-1.798l.04-.007c.296-.048.702-.044 1.398-.038h.005l.61.004h1.014a2.75 2.75 0 0 1 2.75 2.738h-5.39zm.111-7.328c0-.836.683-1.52 1.533-1.52s1.533.684 1.533 1.52-.683 1.52-1.533 1.52-1.533-.684-1.533-1.52m1.533-3.02a3.026 3.026 0 0 0-3.033 3.02 3.026 3.026 0 0 0 3.033 3.02 3.026 3.026 0 0 0 3.033-3.02 3.026 3.026 0 0 0-3.033-3.02" /></svg>);
const TeamIcon32 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => <svg width={32} height={32} viewBox="0 0 32 32" ref={ref} {...props}><path fillRule="evenodd" d="M8.982 10a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-5a5 5 0 1 0 0 10 5 5 0 0 0 0-10M6 22.5A3.5 3.5 0 0 1 9.5 19h4.964a3.5 3.5 0 0 1 3.5 3.5V24H6zM19.23 26h6.87c1.06 0 1.92-.86 1.92-1.92a5.6 5.6 0 0 0-5.6-5.6h-.726c-1.067 0-1.896.01-2.512.103-.237.036-.47.089-.693.17A5.5 5.5 0 0 0 14.464 17H9.5A5.5 5.5 0 0 0 4 22.5V25a1 1 0 0 0 1 1zm2.464-5.52c-1.008 0-1.651.012-2.088.064.231.608.358 1.267.358 1.956V24h6.055a3.6 3.6 0 0 0-3.6-3.52zM18.99 13a2 2 0 1 1 4 0 2 2 0 0 1-4 0m2-4a4 4 0 1 0 0 8 4 4 0 0 0 0-8" /></svg>);

export const TeamIcon = createIcon(TeamIcon16, TeamIcon24, TeamIcon32, "TeamIcon");