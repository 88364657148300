/**
 * This file is generated by the generate-components script. Do not edit directly.
 */
/* eslint-disable */
import { createIcon } from "../createIcon.tsx";
import { forwardRef, type Ref, type SVGProps } from "react";

const EyeVisibleIcon16 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => <svg width={16} height={16} viewBox="0 0 16 16" ref={ref} {...props}><path fillRule="evenodd" d="M4.42 9.76a12 12 0 0 1-1.75-1.727 12 12 0 0 1 1.75-1.728c1.037-.823 2.286-1.486 3.59-1.486 1.318 0 2.578.675 3.618 1.507a12.3 12.3 0 0 1 1.764 1.755c-.648.963-2.456 3.165-5.381 3.165-1.305 0-2.554-.663-3.59-1.486m3.59-6.44c-1.796 0-3.373.897-4.522 1.81a13.7 13.7 0 0 0-2.263 2.31.976.976 0 0 0 0 1.187 13.7 13.7 0 0 0 2.262 2.309c1.149.913 2.726 1.81 4.523 1.81 4.177 0 6.443-3.493 6.862-4.198a.97.97 0 0 0-.062-1.088 13.6 13.6 0 0 0-2.246-2.304C11.413 4.233 9.824 3.32 8.011 3.32m-.958 4.713a.947.947 0 1 1 1.894 0 .947.947 0 0 1-1.894 0M8 5.586a2.447 2.447 0 1 0 0 4.894 2.447 2.447 0 0 0 0-4.894" /></svg>);
const EyeVisibleIcon24 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => <svg width={24} height={24} viewBox="0 0 24 24" ref={ref} {...props}><path fillRule="evenodd" d="M7.043 14.415A16.4 16.4 0 0 1 4.638 12a16.4 16.4 0 0 1 2.405-2.415C8.439 8.46 10.168 7.517 12 7.517c1.846 0 3.586.958 4.988 2.092a16.4 16.4 0 0 1 2.414 2.441c-.34.527-1.033 1.48-2.073 2.369-1.28 1.094-3.052 2.064-5.329 2.064-1.832 0-3.56-.944-4.957-2.068M12 6.017c-2.332 0-4.39 1.186-5.898 2.4a17.8 17.8 0 0 0-2.905 2.991.98.98 0 0 0 0 1.184 17.8 17.8 0 0 0 2.905 2.992c1.507 1.213 3.566 2.399 5.898 2.399 2.734 0 4.84-1.172 6.304-2.424a12.5 12.5 0 0 0 2.576-3.047.98.98 0 0 0-.063-1.085 17.8 17.8 0 0 0-2.885-2.983C16.42 7.22 14.348 6.017 12 6.017M10.493 12a1.49 1.49 0 1 1 2.982 0 1.49 1.49 0 0 1-2.982 0m1.491-2.99a2.99 2.99 0 1 0 0 5.98 2.99 2.99 0 0 0 0-5.98" /></svg>);
const EyeVisibleIcon32 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => <svg width={32} height={32} viewBox="0 0 32 32" ref={ref} {...props}><path fillRule="evenodd" d="M9.357 19.125A21.8 21.8 0 0 1 6.26 16a21.8 21.8 0 0 1 3.096-3.125c1.862-1.517 4.182-2.804 6.643-2.804 2.478 0 4.813 1.305 6.68 2.834a21.8 21.8 0 0 1 3.113 3.162 14.8 14.8 0 0 1-2.663 3.063c-1.7 1.475-4.07 2.799-7.13 2.799-2.461 0-4.781-1.287-6.643-2.804M16 8.07c-3.136 0-5.9 1.62-7.906 3.254A23.5 23.5 0 0 0 4.3 15.263a1.23 1.23 0 0 0 0 1.473c.389.522 1.77 2.291 3.794 3.94 2.006 1.634 4.77 3.253 7.906 3.253 3.679 0 6.497-1.601 8.44-3.288 1.937-1.682 3.05-3.485 3.354-4.012a1.22 1.22 0 0 0-.079-1.345 23.5 23.5 0 0 0-3.768-3.926C21.937 9.712 19.157 8.07 16 8.07M14.012 16a1.967 1.967 0 1 1 3.934 0 1.967 1.967 0 0 1-3.934 0m1.967-3.968a3.967 3.967 0 1 0 0 7.934 3.967 3.967 0 0 0 0-7.934" /></svg>);

export const EyeVisibleIcon = createIcon(EyeVisibleIcon16, EyeVisibleIcon24, EyeVisibleIcon32, "EyeVisibleIcon");