/**
 * This file is generated by the generate-components script. Do not edit directly.
 */
/* eslint-disable */
import { createIcon } from "../createIcon.tsx";
import { forwardRef, type Ref, type SVGProps } from "react";

const ReactionIcon16 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => <svg width={16} height={16} viewBox="0 0 16 16" ref={ref} {...props}><path fillRule="evenodd" d="M12.742 1.011a.75.75 0 0 1 .75.75v.75h.75a.75.75 0 0 1 0 1.5h-.75v.75a.75.75 0 0 1-1.5 0v-.75h-.75a.75.75 0 0 1 0-1.5h.75v-.75a.75.75 0 0 1 .75-.75M7.998 13.5a5.494 5.494 0 0 1 0-10.989.75.75 0 1 0 0-1.5 6.994 6.994 0 1 0 6.994 6.995.75.75 0 0 0-1.5 0A5.494 5.494 0 0 1 7.998 13.5M5.159 7.022a.852.852 0 1 0 0-1.703.852.852 0 0 0 0 1.703m5.677 0a.852.852 0 1 0 0-1.703.852.852 0 0 0 0 1.703m-5.807.813a.75.75 0 0 1 .869.607c.16.908 1.014 1.652 2.1 1.652s1.939-.744 2.1-1.652a.75.75 0 1 1 1.477.262c-.296 1.67-1.81 2.89-3.577 2.89s-3.281-1.22-3.577-2.89a.75.75 0 0 1 .608-.87" /></svg>);
const ReactionIcon24 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => <svg width={24} height={24} viewBox="0 0 24 24" ref={ref} {...props}><path fillRule="evenodd" d="M18.25 2a.75.75 0 0 1 .75.75V5h2.25a.75.75 0 0 1 0 1.5H19v2.25a.75.75 0 0 1-1.5 0V6.5h-2.25a.75.75 0 0 1 0-1.5h2.25V2.75a.75.75 0 0 1 .75-.75M4.5 12A7.5 7.5 0 0 1 12 4.5.75.75 0 0 0 12 3a9 9 0 1 0 9 9 .75.75 0 0 0-1.5 0 7.5 7.5 0 0 1-15 0M8 11a1 1 0 1 0 0-2 1 1 0 0 0 0 2m9-1a1 1 0 1 1-2 0 1 1 0 0 1 2 0m-9.51 2.628a.75.75 0 0 0-1.48.245C6.49 15.775 8.981 18 12 18c3.018 0 5.51-2.225 5.99-5.127a.75.75 0 1 0-1.48-.245C16.145 14.833 14.257 16.5 12 16.5s-4.145-1.667-4.51-3.872" /></svg>);
const ReactionIcon32 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => <svg width={32} height={32} viewBox="0 0 32 32" ref={ref} {...props}><path fillRule="evenodd" d="M25 2a1 1 0 0 1 1 1v3h3a1 1 0 1 1 0 2h-3v3a1 1 0 0 1-2 0V8h-3a1 1 0 1 1 0-2h3V3a1 1 0 0 1 1-1M6 16c0-5.523 4.477-10 10-10a1 1 0 1 0 0-2C9.373 4 4 9.373 4 16s5.373 12 12 12 12-5.373 12-12a1 1 0 1 0-2 0c0 5.523-4.477 10-10 10S6 21.523 6 16m5.25-1.5a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5M22 13.25a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0M9.987 16.837a1 1 0 0 0-1.974.326C8.653 21.033 11.975 24 16 24s7.347-2.967 7.987-6.837a1 1 0 1 0-1.974-.326C21.527 19.777 19.01 22 16 22s-5.527-2.222-6.013-5.163" /></svg>);

export const ReactionIcon = createIcon(ReactionIcon16, ReactionIcon24, ReactionIcon32, "ReactionIcon");