/**
 * This file is generated by Style Dictionary. Do not edit directly.
 */

export const HopperVariablePrefix = "--hop";

export const HopperColors = {
    "core_coastal-25": "coastal-25",
    "core_coastal-50": "coastal-50",
    "core_coastal-75": "coastal-75",
    "core_coastal-100": "coastal-100",
    "core_coastal-200": "coastal-200",
    "core_coastal-300": "coastal-300",
    "core_coastal-400": "coastal-400",
    "core_coastal-500": "coastal-500",
    "core_coastal-600": "coastal-600",
    "core_coastal-700": "coastal-700",
    "core_coastal-800": "coastal-800",
    "core_coastal-900": "coastal-900",
    "core_quetzal-25": "quetzal-25",
    "core_quetzal-50": "quetzal-50",
    "core_quetzal-75": "quetzal-75",
    "core_quetzal-100": "quetzal-100",
    "core_quetzal-200": "quetzal-200",
    "core_quetzal-300": "quetzal-300",
    "core_quetzal-400": "quetzal-400",
    "core_quetzal-500": "quetzal-500",
    "core_quetzal-600": "quetzal-600",
    "core_quetzal-700": "quetzal-700",
    "core_quetzal-800": "quetzal-800",
    "core_quetzal-900": "quetzal-900",
    "core_orchid-bloom-25": "orchid-bloom-25",
    "core_orchid-bloom-50": "orchid-bloom-50",
    "core_orchid-bloom-75": "orchid-bloom-75",
    "core_orchid-bloom-100": "orchid-bloom-100",
    "core_orchid-bloom-200": "orchid-bloom-200",
    "core_orchid-bloom-300": "orchid-bloom-300",
    "core_orchid-bloom-400": "orchid-bloom-400",
    "core_orchid-bloom-500": "orchid-bloom-500",
    "core_orchid-bloom-600": "orchid-bloom-600",
    "core_orchid-bloom-700": "orchid-bloom-700",
    "core_orchid-bloom-800": "orchid-bloom-800",
    "core_orchid-bloom-900": "orchid-bloom-900",
    "core_sapphire-25": "sapphire-25",
    "core_sapphire-50": "sapphire-50",
    "core_sapphire-75": "sapphire-75",
    "core_sapphire-100": "sapphire-100",
    "core_sapphire-200": "sapphire-200",
    "core_sapphire-300": "sapphire-300",
    "core_sapphire-400": "sapphire-400",
    "core_sapphire-500": "sapphire-500",
    "core_sapphire-600": "sapphire-600",
    "core_sapphire-700": "sapphire-700",
    "core_sapphire-800": "sapphire-800",
    "core_sapphire-900": "sapphire-900",
    "core_fog-25": "fog-25",
    "core_fog-50": "fog-50",
    "core_fog-75": "fog-75",
    "core_fog-100": "fog-100",
    "core_fog-200": "fog-200",
    "core_fog-300": "fog-300",
    "core_fog-400": "fog-400",
    "core_fog-500": "fog-500",
    "core_fog-600": "fog-600",
    "core_fog-700": "fog-700",
    "core_fog-800": "fog-800",
    "core_fog-900": "fog-900",
    "core_toad-25": "toad-25",
    "core_toad-50": "toad-50",
    "core_toad-75": "toad-75",
    "core_toad-100": "toad-100",
    "core_toad-200": "toad-200",
    "core_toad-300": "toad-300",
    "core_toad-400": "toad-400",
    "core_toad-500": "toad-500",
    "core_toad-600": "toad-600",
    "core_toad-700": "toad-700",
    "core_toad-800": "toad-800",
    "core_toad-900": "toad-900",
    "core_sunken-treasure-25": "sunken-treasure-25",
    "core_sunken-treasure-50": "sunken-treasure-50",
    "core_sunken-treasure-75": "sunken-treasure-75",
    "core_sunken-treasure-100": "sunken-treasure-100",
    "core_sunken-treasure-200": "sunken-treasure-200",
    "core_sunken-treasure-300": "sunken-treasure-300",
    "core_sunken-treasure-400": "sunken-treasure-400",
    "core_sunken-treasure-500": "sunken-treasure-500",
    "core_sunken-treasure-600": "sunken-treasure-600",
    "core_sunken-treasure-700": "sunken-treasure-700",
    "core_sunken-treasure-800": "sunken-treasure-800",
    "core_sunken-treasure-900": "sunken-treasure-900",
    "core_koi-25": "koi-25",
    "core_koi-50": "koi-50",
    "core_koi-75": "koi-75",
    "core_koi-100": "koi-100",
    "core_koi-200": "koi-200",
    "core_koi-300": "koi-300",
    "core_koi-400": "koi-400",
    "core_koi-500": "koi-500",
    "core_koi-600": "koi-600",
    "core_koi-700": "koi-700",
    "core_koi-800": "koi-800",
    "core_koi-900": "koi-900",
    "core_amanita-25": "amanita-25",
    "core_amanita-50": "amanita-50",
    "core_amanita-75": "amanita-75",
    "core_amanita-100": "amanita-100",
    "core_amanita-200": "amanita-200",
    "core_amanita-300": "amanita-300",
    "core_amanita-400": "amanita-400",
    "core_amanita-500": "amanita-500",
    "core_amanita-600": "amanita-600",
    "core_amanita-700": "amanita-700",
    "core_amanita-800": "amanita-800",
    "core_amanita-900": "amanita-900",
    "core_moss-25": "moss-25",
    "core_moss-50": "moss-50",
    "core_moss-75": "moss-75",
    "core_moss-100": "moss-100",
    "core_moss-200": "moss-200",
    "core_moss-300": "moss-300",
    "core_moss-400": "moss-400",
    "core_moss-500": "moss-500",
    "core_moss-600": "moss-600",
    "core_moss-700": "moss-700",
    "core_moss-800": "moss-800",
    "core_moss-900": "moss-900",
    "core_abyss": "abyss",
    "core_rock-20": "rock-20",
    "core_rock-25": "rock-25",
    "core_rock-50": "rock-50",
    "core_rock-75": "rock-75",
    "core_rock-100": "rock-100",
    "core_rock-200": "rock-200",
    "core_rock-300": "rock-300",
    "core_rock-400": "rock-400",
    "core_rock-500": "rock-500",
    "core_rock-600": "rock-600",
    "core_rock-700": "rock-700",
    "core_rock-800": "rock-800",
    "core_rock-900": "rock-900",
    "core_samoyed": "samoyed"
};

export const BackgroundColors = {
    "danger-active": "danger-surface-active",
    "danger-weak-active": "danger-surface-weak-active",
    "danger": "danger-surface",
    "danger-selected": "danger-surface-selected",
    "danger-disabled": "danger-surface-disabled",
    "danger-hover": "danger-surface-hover",
    "danger-press": "danger-surface-press",
    "danger-strong": "danger-surface-strong",
    "danger-strong-hover": "danger-surface-strong-hover",
    "danger-weak": "danger-surface-weak",
    "danger-weak-hover": "danger-surface-weak-hover",
    "danger-weak-press": "danger-surface-weak-press",
    "neutral-active": "neutral-surface-active",
    "neutral-weak-active": "neutral-surface-weak-active",
    "neutral": "neutral-surface",
    "neutral-selected": "neutral-surface-selected",
    "neutral-disabled": "neutral-surface-disabled",
    "neutral-hover": "neutral-surface-hover",
    "neutral-press": "neutral-surface-press",
    "neutral-strong": "neutral-surface-strong",
    "neutral-weak": "neutral-surface-weak",
    "neutral-weak-selected": "neutral-surface-weak-selected",
    "neutral-weak-hover": "neutral-surface-weak-hover",
    "neutral-weak-press": "neutral-surface-weak-press",
    "neutral-weakest": "neutral-surface-weakest",
    "neutral-weakest-selected": "neutral-surface-weakest-selected",
    "neutral-weakest-hover": "neutral-surface-weakest-hover",
    "primary-active": "primary-surface-active",
    "primary-strong-active": "primary-surface-strong-active",
    "primary-weak-active": "primary-surface-weak-active",
    "primary": "primary-surface",
    "primary-selected": "primary-surface-selected",
    "primary-disabled": "primary-surface-disabled",
    "primary-focus": "primary-surface-focus",
    "primary-hover": "primary-surface-hover",
    "primary-press": "primary-surface-press",
    "primary-strong": "primary-surface-strong",
    "primary-strong-selected": "primary-surface-strong-selected",
    "primary-strong-hover": "primary-surface-strong-hover",
    "primary-strong-press": "primary-surface-strong-press",
    "primary-weak": "primary-surface-weak",
    "primary-weak-hover": "primary-surface-weak-hover",
    "primary-weak-press": "primary-surface-weak-press",
    "upsell-active": "upsell-surface-active",
    "upsell-weak-active": "upsell-surface-weak-active",
    "upsell": "upsell-surface",
    "upsell-selected": "upsell-surface-selected",
    "upsell-disabled": "upsell-surface-disabled",
    "upsell-hover": "upsell-surface-hover",
    "upsell-press": "upsell-surface-press",
    "upsell-weak": "upsell-surface-weak",
    "upsell-weak-hover": "upsell-surface-weak-hover",
    "upsell-weak-press": "upsell-surface-weak-press",
    "decorative-option9": "decorative-option9-surface",
    "decorative-option9-hover": "decorative-option9-surface-hover",
    "decorative-option9-strong": "decorative-option9-surface-strong",
    "decorative-option9-weak": "decorative-option9-surface-weak",
    "decorative-option9-weak-hover": "decorative-option9-surface-weak-hover",
    "decorative-option9-weakest": "decorative-option9-surface-weakest",
    "decorative-option8": "decorative-option8-surface",
    "decorative-option8-hover": "decorative-option8-surface-hover",
    "decorative-option8-strong": "decorative-option8-surface-strong",
    "decorative-option8-weak": "decorative-option8-surface-weak",
    "decorative-option8-weak-hover": "decorative-option8-surface-weak-hover",
    "decorative-option8-weakest": "decorative-option8-surface-weakest",
    "decorative-option7": "decorative-option7-surface",
    "decorative-option7-hover": "decorative-option7-surface-hover",
    "decorative-option7-strong": "decorative-option7-surface-strong",
    "decorative-option7-weak": "decorative-option7-surface-weak",
    "decorative-option7-weak-hover": "decorative-option7-surface-weak-hover",
    "decorative-option7-weakest": "decorative-option7-surface-weakest",
    "decorative-option5": "decorative-option5-surface",
    "decorative-option5-hover": "decorative-option5-surface-hover",
    "decorative-option5-strong": "decorative-option5-surface-strong",
    "decorative-option5-weak": "decorative-option5-surface-weak",
    "decorative-option5-weak-hover": "decorative-option5-surface-weak-hover",
    "decorative-option5-weakest": "decorative-option5-surface-weakest",
    "decorative-option6": "decorative-option6-surface",
    "decorative-option6-hover": "decorative-option6-surface-hover",
    "decorative-option6-strong": "decorative-option6-surface-strong",
    "decorative-option6-weak": "decorative-option6-surface-weak",
    "decorative-option6-weak-hover": "decorative-option6-surface-weak-hover",
    "decorative-option6-weakest": "decorative-option6-surface-weakest",
    "decorative-option4": "decorative-option4-surface",
    "decorative-option4-hover": "decorative-option4-surface-hover",
    "decorative-option4-strong": "decorative-option4-surface-strong",
    "decorative-option4-weak": "decorative-option4-surface-weak",
    "decorative-option4-weak-hover": "decorative-option4-surface-weak-hover",
    "decorative-option4-weakest": "decorative-option4-surface-weakest",
    "decorative-option3": "decorative-option3-surface",
    "decorative-option3-hover": "decorative-option3-surface-hover",
    "decorative-option3-strong": "decorative-option3-surface-strong",
    "decorative-option3-weak": "decorative-option3-surface-weak",
    "decorative-option3-weak-hover": "decorative-option3-surface-weak-hover",
    "decorative-option3-weakest": "decorative-option3-surface-weakest",
    "decorative-option2": "decorative-option2-surface",
    "decorative-option2-hover": "decorative-option2-surface-hover",
    "decorative-option2-strong": "decorative-option2-surface-strong",
    "decorative-option2-weak": "decorative-option2-surface-weak",
    "decorative-option2-weak-hover": "decorative-option2-surface-weak-hover",
    "decorative-option2-weakest": "decorative-option2-surface-weakest",
    "decorative-option1": "decorative-option1-surface",
    "decorative-option1-hover": "decorative-option1-surface-hover",
    "decorative-option1-strong": "decorative-option1-surface-strong",
    "decorative-option1-weak": "decorative-option1-surface-weak",
    "decorative-option1-weak-hover": "decorative-option1-surface-weak-hover",
    "decorative-option1-weakest": "decorative-option1-surface-weakest",
    "information": "information-surface",
    "information-strong": "information-surface-strong",
    "information-weak": "information-surface-weak",
    "status-caution": "status-caution-surface",
    "status-caution-disabled": "status-caution-surface-disabled",
    "status-caution-hover": "status-caution-surface-hover",
    "status-caution-press": "status-caution-surface-press",
    "status-caution-selected": "status-caution-surface-selected",
    "status-caution-strong": "status-caution-surface-strong",
    "status-inactive": "status-inactive-surface",
    "status-inactive-disabled": "status-inactive-surface-disabled",
    "status-inactive-hover": "status-inactive-surface-hover",
    "status-inactive-press": "status-inactive-surface-press",
    "status-inactive-selected": "status-inactive-surface-selected",
    "status-inactive-strong": "status-inactive-surface-strong",
    "status-negative": "status-negative-surface",
    "status-negative-disabled": "status-negative-surface-disabled",
    "status-negative-hover": "status-negative-surface-hover",
    "status-negative-press": "status-negative-surface-press",
    "status-negative-selected": "status-negative-surface-selected",
    "status-negative-strong": "status-negative-surface-strong",
    "status-neutral": "status-neutral-surface",
    "status-neutral-disabled": "status-neutral-surface-disabled",
    "status-neutral-hover": "status-neutral-surface-hover",
    "status-neutral-press": "status-neutral-surface-press",
    "status-neutral-selected": "status-neutral-surface-selected",
    "status-neutral-strong": "status-neutral-surface-strong",
    "status-option1": "status-option1-surface",
    "status-option1-disabled": "status-option1-surface-disabled",
    "status-option1-hover": "status-option1-surface-hover",
    "status-option1-press": "status-option1-surface-press",
    "status-option1-selected": "status-option1-surface-selected",
    "status-option1-strong": "status-option1-surface-strong",
    "status-option2": "status-option2-surface",
    "status-option2-disabled": "status-option2-surface-disabled",
    "status-option2-hover": "status-option2-surface-hover",
    "status-option2-press": "status-option2-surface-press",
    "status-option2-selected": "status-option2-surface-selected",
    "status-option2-strong": "status-option2-surface-strong",
    "status-option3": "status-option3-surface",
    "status-option3-disabled": "status-option3-surface-disabled",
    "status-option3-hover": "status-option3-surface-hover",
    "status-option3-press": "status-option3-surface-press",
    "status-option3-selected": "status-option3-surface-selected",
    "status-option3-strong": "status-option3-surface-strong",
    "status-option4": "status-option4-surface",
    "status-option4-disabled": "status-option4-surface-disabled",
    "status-option4-hover": "status-option4-surface-hover",
    "status-option4-press": "status-option4-surface-press",
    "status-option4-selected": "status-option4-surface-selected",
    "status-option4-strong": "status-option4-surface-strong",
    "status-option5": "status-option5-surface",
    "status-option5-disabled": "status-option5-surface-disabled",
    "status-option5-hover": "status-option5-surface-hover",
    "status-option5-press": "status-option5-surface-press",
    "status-option5-selected": "status-option5-surface-selected",
    "status-option5-strong": "status-option5-surface-strong",
    "status-option6": "status-option6-surface",
    "status-option6-disabled": "status-option6-surface-disabled",
    "status-option6-hover": "status-option6-surface-hover",
    "status-option6-press": "status-option6-surface-press",
    "status-option6-selected": "status-option6-surface-selected",
    "status-option6-strong": "status-option6-surface-strong",
    "status-positive": "status-positive-surface",
    "status-positive-disabled": "status-positive-surface-disabled",
    "status-positive-hover": "status-positive-surface-hover",
    "status-positive-press": "status-positive-surface-press",
    "status-positive-selected": "status-positive-surface-selected",
    "status-positive-strong": "status-positive-surface-strong",
    "status-progress": "status-progress-surface",
    "status-progress-disabled": "status-progress-surface-disabled",
    "status-progress-hover": "status-progress-surface-hover",
    "status-progress-press": "status-progress-surface-press",
    "status-progress-selected": "status-progress-surface-selected",
    "status-progress-strong": "status-progress-surface-strong",
    "success": "success-surface",
    "success-strong": "success-surface-strong",
    "success-weak": "success-surface-weak",
    "warning": "warning-surface",
    "warning-strong": "warning-surface-strong",
    "warning-weak": "warning-surface-weak"
};

export const TextColors = {
    "danger-active": "danger-text-active",
    "danger-weak-active": "danger-text-weak-active",
    "danger": "danger-text",
    "danger-selected": "danger-text-selected",
    "danger-disabled": "danger-text-disabled",
    "danger-hover": "danger-text-hover",
    "danger-press": "danger-text-press",
    "danger-strong": "danger-text-strong",
    "danger-strong-hover": "danger-text-strong-hover",
    "danger-weak": "danger-text-weak",
    "danger-weak-hover": "danger-text-weak-hover",
    "danger-weak-press": "danger-text-weak-press",
    "neutral-active": "neutral-text-active",
    "neutral-weak-active": "neutral-text-weak-active",
    "neutral": "neutral-text",
    "neutral-selected": "neutral-text-selected",
    "neutral-disabled": "neutral-text-disabled",
    "neutral-hover": "neutral-text-hover",
    "neutral-press": "neutral-text-press",
    "neutral-strong": "neutral-text-strong",
    "neutral-weak": "neutral-text-weak",
    "neutral-weak-hover": "neutral-text-weak-hover",
    "neutral-weak-press": "neutral-text-weak-press",
    "neutral-weak-selected": "neutral-text-weak-selected",
    "neutral-weakest": "neutral-text-weakest",
    "primary-active": "primary-text-active",
    "primary": "primary-text",
    "primary-selected": "primary-text-selected",
    "primary-disabled": "primary-text-disabled",
    "primary-hover": "primary-text-hover",
    "primary-press": "primary-text-press",
    "primary-strong": "primary-text-strong",
    "primary-strong-hover": "primary-text-strong-hover",
    "upsell-active": "upsell-text-active",
    "upsell-weak-active": "upsell-text-weak-active",
    "upsell": "upsell-text",
    "upsell-selected": "upsell-text-selected",
    "upsell-disabled": "upsell-text-disabled",
    "upsell-hover": "upsell-text-hover",
    "upsell-press": "upsell-text-press",
    "upsell-weak": "upsell-text-weak",
    "upsell-weak-hover": "upsell-text-weak-hover",
    "upsell-weak-press": "upsell-text-weak-press",
    "decorative-option9": "decorative-option9-text",
    "decorative-option9-weak": "decorative-option9-text-weak",
    "decorative-option8": "decorative-option8-text",
    "decorative-option8-weak": "decorative-option8-text-weak",
    "decorative-option7": "decorative-option7-text",
    "decorative-option7-weak": "decorative-option7-text-weak",
    "decorative-option5": "decorative-option5-text",
    "decorative-option5-weak": "decorative-option5-text-weak",
    "decorative-option6": "decorative-option6-text",
    "decorative-option6-weak": "decorative-option6-text-weak",
    "decorative-option4": "decorative-option4-text",
    "decorative-option4-weak": "decorative-option4-text-weak",
    "decorative-option3": "decorative-option3-text",
    "decorative-option3-weak": "decorative-option3-text-weak",
    "decorative-option2": "decorative-option2-text",
    "decorative-option2-weak": "decorative-option2-text-weak",
    "decorative-option1": "decorative-option1-text",
    "decorative-option1-weak": "decorative-option1-text-weak",
    "information": "information-text",
    "information-weak": "information-text-weak",
    "status-caution": "status-caution-text",
    "status-caution-disabled": "status-caution-text-disabled",
    "status-caution-hover": "status-caution-text-hover",
    "status-caution-press": "status-caution-text-press",
    "status-caution-selected": "status-caution-text-selected",
    "status-inactive": "status-inactive-text",
    "status-inactive-disabled": "status-inactive-text-disabled",
    "status-inactive-hover": "status-inactive-text-hover",
    "status-inactive-press": "status-inactive-text-press",
    "status-inactive-selected": "status-inactive-text-selected",
    "status-negative": "status-negative-text",
    "status-negative-disabled": "status-negative-text-disabled",
    "status-negative-hover": "status-negative-text-hover",
    "status-negative-press": "status-negative-text-press",
    "status-negative-selected": "status-negative-text-selected",
    "status-neutral": "status-neutral-text",
    "status-neutral-disabled": "status-neutral-text-disabled",
    "status-neutral-hover": "status-neutral-text-hover",
    "status-neutral-press": "status-neutral-text-press",
    "status-neutral-selected": "status-neutral-text-selected",
    "status-option1": "status-option1-text",
    "status-option1-disabled": "status-option1-text-disabled",
    "status-option1-hover": "status-option1-text-hover",
    "status-option1-press": "status-option1-text-press",
    "status-option1-selected": "status-option1-text-selected",
    "status-option2": "status-option2-text",
    "status-option2-disabled": "status-option2-text-disabled",
    "status-option2-hover": "status-option2-text-hover",
    "status-option2-press": "status-option2-text-press",
    "status-option2-selected": "status-option2-text-selected",
    "status-option3": "status-option3-text",
    "status-option3-disabled": "status-option3-text-disabled",
    "status-option3-hover": "status-option3-text-hover",
    "status-option3-press": "status-option3-text-press",
    "status-option3-selected": "status-option3-text-selected",
    "status-option4": "status-option4-text",
    "status-option4-disabled": "status-option4-text-disabled",
    "status-option4-hover": "status-option4-text-hover",
    "status-option4-press": "status-option4-text-press",
    "status-option4-selected": "status-option4-text-selected",
    "status-option5": "status-option5-text",
    "status-option5-disabled": "status-option5-text-disabled",
    "status-option5-hover": "status-option5-text-hover",
    "status-option5-press": "status-option5-text-press",
    "status-option5-selected": "status-option5-text-selected",
    "status-option6": "status-option6-text",
    "status-option6-disabled": "status-option6-text-disabled",
    "status-option6-hover": "status-option6-text-hover",
    "status-option6-press": "status-option6-text-press",
    "status-option6-selected": "status-option6-text-selected",
    "status-positive": "status-positive-text",
    "status-positive-disabled": "status-positive-text-disabled",
    "status-positive-hover": "status-positive-text-hover",
    "status-positive-press": "status-positive-text-press",
    "status-positive-selected": "status-positive-text-selected",
    "status-progress": "status-progress-text",
    "status-progress-disabled": "status-progress-text-disabled",
    "status-progress-hover": "status-progress-text-hover",
    "status-progress-press": "status-progress-text-press",
    "status-progress-selected": "status-progress-text-selected",
    "success": "success-text",
    "success-hover": "success-text-hover",
    "success-weak": "success-text-weak",
    "warning": "warning-text",
    "warning-weak": "warning-text-weak"
};

export const IconColors = {
    "danger-active": "danger-icon-active",
    "danger-weak-active": "danger-icon-weak-active",
    "danger": "danger-icon",
    "danger-selected": "danger-icon-selected",
    "danger-disabled": "danger-icon-disabled",
    "danger-hover": "danger-icon-hover",
    "danger-press": "danger-icon-press",
    "danger-strong": "danger-icon-strong",
    "danger-strong-hover": "danger-icon-strong-hover",
    "danger-weak": "danger-icon-weak",
    "danger-weak-hover": "danger-icon-weak-hover",
    "danger-weak-press": "danger-icon-weak-press",
    "neutral-active": "neutral-icon-active",
    "neutral-weak-active": "neutral-icon-weak-active",
    "neutral": "neutral-icon",
    "neutral-selected": "neutral-icon-selected",
    "neutral-disabled": "neutral-icon-disabled",
    "neutral-hover": "neutral-icon-hover",
    "neutral-press": "neutral-icon-press",
    "neutral-strong": "neutral-icon-strong",
    "neutral-strong-hover": "neutral-icon-strong-hover",
    "neutral-weak": "neutral-icon-weak",
    "neutral-weak-hover": "neutral-icon-weak-hover",
    "neutral-weak-press": "neutral-icon-weak-press",
    "neutral-weak-selected": "neutral-icon-weak-selected",
    "neutral-weakest": "neutral-icon-weakest",
    "primary-active": "primary-icon-active",
    "primary": "primary-icon",
    "primary-selected": "primary-icon-selected",
    "primary-disabled": "primary-icon-disabled",
    "primary-hover": "primary-icon-hover",
    "primary-press": "primary-icon-press",
    "primary-strong": "primary-icon-strong",
    "primary-strong-hover": "primary-icon-strong-hover",
    "upsell-active": "upsell-icon-active",
    "upsell-weak-active": "upsell-icon-weak-active",
    "upsell": "upsell-icon",
    "upsell-selected": "upsell-icon-selected",
    "upsell-hover": "upsell-icon-hover",
    "upsell-press": "upsell-icon-press",
    "upsell-weakest": "upsell-icon-weakest",
    "upsell-weak": "upsell-icon-weak",
    "upsell-weak-hover": "upsell-icon-weak-hover",
    "upsell-weak-press": "upsell-icon-weak-press",
    "decorative-option9": "decorative-option9-icon",
    "decorative-option8": "decorative-option8-icon",
    "decorative-option7": "decorative-option7-icon",
    "decorative-option5": "decorative-option5-icon",
    "decorative-option6": "decorative-option6-icon",
    "decorative-option4": "decorative-option4-icon",
    "decorative-option3": "decorative-option3-icon",
    "decorative-option2": "decorative-option2-icon",
    "decorative-option1": "decorative-option1-icon",
    "information": "information-icon",
    "information-weakest": "information-icon-weakest",
    "information-weak": "information-icon-weak",
    "status-caution": "status-caution-icon",
    "status-caution-disabled": "status-caution-icon-disabled",
    "status-caution-hover": "status-caution-icon-hover",
    "status-caution-press": "status-caution-icon-press",
    "status-caution-selected": "status-caution-icon-selected",
    "status-inactive": "status-inactive-icon",
    "status-inactive-disabled": "status-inactive-icon-disabled",
    "status-inactive-hover": "status-inactive-icon-hover",
    "status-inactive-press": "status-inactive-icon-press",
    "status-inactive-selected": "status-inactive-icon-selected",
    "status-negative": "status-negative-icon",
    "status-negative-disabled": "status-negative-icon-disabled",
    "status-negative-hover": "status-negative-icon-hover",
    "status-negative-press": "status-negative-icon-press",
    "status-negative-selected": "status-negative-icon-selected",
    "status-neutral": "status-neutral-icon",
    "status-neutral-disabled": "status-neutral-icon-disabled",
    "status-neutral-hover": "status-neutral-icon-hover",
    "status-neutral-press": "status-neutral-icon-press",
    "status-neutral-selected": "status-neutral-icon-selected",
    "status-option1": "status-option1-icon",
    "status-option1-disabled": "status-option1-icon-disabled",
    "status-option1-hover": "status-option1-icon-hover",
    "status-option1-press": "status-option1-icon-press",
    "status-option1-selected": "status-option1-icon-selected",
    "status-option2": "status-option2-icon",
    "status-option2-disabled": "status-option2-icon-disabled",
    "status-option2-hover": "status-option2-icon-hover",
    "status-option2-press": "status-option2-icon-press",
    "status-option2-selected": "status-option2-icon-selected",
    "status-option3": "status-option3-icon",
    "status-option3-disabled": "status-option3-icon-disabled",
    "status-option3-hover": "status-option3-icon-hover",
    "status-option3-press": "status-option3-icon-press",
    "status-option3-selected": "status-option3-icon-selected",
    "status-option4": "status-option4-icon",
    "status-option4-disabled": "status-option4-icon-disabled",
    "status-option4-hover": "status-option4-icon-hover",
    "status-option4-press": "status-option4-icon-press",
    "status-option4-selected": "status-option4-icon-selected",
    "status-option5": "status-option5-icon",
    "status-option5-disabled": "status-option5-icon-disabled",
    "status-option5-hover": "status-option5-icon-hover",
    "status-option5-press": "status-option5-icon-press",
    "status-option5-selected": "status-option5-icon-selected",
    "status-option6": "status-option6-icon",
    "status-option6-disabled": "status-option6-icon-disabled",
    "status-option6-hover": "status-option6-icon-hover",
    "status-option6-press": "status-option6-icon-press",
    "status-option6-selected": "status-option6-icon-selected",
    "status-positive": "status-positive-icon",
    "status-positive-disabled": "status-positive-icon-disabled",
    "status-positive-hover": "status-positive-icon-hover",
    "status-positive-press": "status-positive-icon-press",
    "status-positive-selected": "status-positive-icon-selected",
    "status-progress": "status-progress-icon",
    "status-progress-disabled": "status-progress-icon-disabled",
    "status-progress-hover": "status-progress-icon-hover",
    "status-progress-press": "status-progress-icon-press",
    "status-progress-selected": "status-progress-icon-selected",
    "success": "success-icon",
    "success-weakest": "success-icon-weakest",
    "success-weak": "success-icon-weak",
    "warning": "warning-icon",
    "warning-weakest": "warning-icon-weakest",
    "warning-weak": "warning-icon-weak"
};

export const BorderColors = {
    "danger-active": "danger-border-active",
    "danger": "danger-border",
    "danger-selected": "danger-border-selected",
    "danger-strong": "danger-border-strong",
    "danger-press": "danger-border-press",
    "neutral-active": "neutral-border-active",
    "neutral": "neutral-border",
    "neutral-selected": "neutral-border-selected",
    "neutral-disabled": "neutral-border-disabled",
    "neutral-hover": "neutral-border-hover",
    "neutral-press": "neutral-border-press",
    "neutral-strong": "neutral-border-strong",
    "neutral-strong-hover": "neutral-border-strong-hover",
    "neutral-weak": "neutral-border-weak",
    "neutral-weakest": "neutral-border-weakest",
    "primary-active": "primary-border-active",
    "primary": "primary-border",
    "primary-selected": "primary-border-selected",
    "primary-focus": "primary-border-focus",
    "primary-press": "primary-border-press",
    "upsell-active": "upsell-border-active",
    "upsell": "upsell-border",
    "upsell-selected": "upsell-border-selected",
    "upsell-disabled": "upsell-border-disabled",
    "upsell-press": "upsell-border-press",
    "decorative-option9": "decorative-option9-border",
    "decorative-option8": "decorative-option8-border",
    "decorative-option7": "decorative-option7-border",
    "decorative-option5": "decorative-option5-border",
    "decorative-option6": "decorative-option6-border",
    "decorative-option4": "decorative-option4-border",
    "decorative-option3": "decorative-option3-border",
    "decorative-option2": "decorative-option2-border",
    "decorative-option1": "decorative-option1-border",
    "information": "information-border",
    "status-caution": "status-caution-border",
    "status-caution-disabled": "status-caution-border-disabled",
    "status-caution-hover": "status-caution-border-hover",
    "status-caution-press": "status-caution-border-press",
    "status-caution-selected": "status-caution-border-selected",
    "status-inactive": "status-inactive-border",
    "status-inactive-disabled": "status-inactive-border-disabled",
    "status-inactive-hover": "status-inactive-border-hover",
    "status-inactive-press": "status-inactive-border-press",
    "status-inactive-selected": "status-inactive-border-selected",
    "status-negative": "status-negative-border",
    "status-negative-disabled": "status-negative-border-disabled",
    "status-negative-hover": "status-negative-border-hover",
    "status-negative-press": "status-negative-border-press",
    "status-negative-selected": "status-negative-border-selected",
    "status-neutral": "status-neutral-border",
    "status-neutral-disabled": "status-neutral-border-disabled",
    "status-neutral-hover": "status-neutral-border-hover",
    "status-neutral-press": "status-neutral-border-press",
    "status-neutral-selected": "status-neutral-border-selected",
    "status-option1": "status-option1-border",
    "status-option1-disabled": "status-option1-border-disabled",
    "status-option1-hover": "status-option1-border-hover",
    "status-option1-press": "status-option1-border-press",
    "status-option1-selected": "status-option1-border-selected",
    "status-option2": "status-option2-border",
    "status-option2-disabled": "status-option2-border-disabled",
    "status-option2-hover": "status-option2-border-hover",
    "status-option2-press": "status-option2-border-press",
    "status-option2-selected": "status-option2-border-selected",
    "status-option3": "status-option3-border",
    "status-option3-disabled": "status-option3-border-disabled",
    "status-option3-hover": "status-option3-border-hover",
    "status-option3-press": "status-option3-border-press",
    "status-option3-selected": "status-option3-border-selected",
    "status-option4": "status-option4-border",
    "status-option4-disabled": "status-option4-border-disabled",
    "status-option4-hover": "status-option4-border-hover",
    "status-option4-press": "status-option4-border-press",
    "status-option4-selected": "status-option4-border-selected",
    "status-option5": "status-option5-border",
    "status-option5-disabled": "status-option5-border-disabled",
    "status-option5-hover": "status-option5-border-hover",
    "status-option5-press": "status-option5-border-press",
    "status-option5-selected": "status-option5-border-selected",
    "status-option6": "status-option6-border",
    "status-option6-disabled": "status-option6-border-disabled",
    "status-option6-hover": "status-option6-border-hover",
    "status-option6-press": "status-option6-border-press",
    "status-option6-selected": "status-option6-border-selected",
    "status-positive": "status-positive-border",
    "status-positive-disabled": "status-positive-border-disabled",
    "status-positive-hover": "status-positive-border-hover",
    "status-positive-press": "status-positive-border-press",
    "status-positive-selected": "status-positive-border-selected",
    "status-progress": "status-progress-border",
    "status-progress-disabled": "status-progress-border-disabled",
    "status-progress-hover": "status-progress-border-hover",
    "status-progress-press": "status-progress-border-press",
    "status-progress-selected": "status-progress-border-selected",
    "success": "success-border",
    "warning": "warning-border"
};

export const DataVizColors = {
    "dataviz_monochromatic-primary-25": "dataviz-monochromatic-primary-25",
    "dataviz_monochromatic-primary-50": "dataviz-monochromatic-primary-50",
    "dataviz_monochromatic-primary-75": "dataviz-monochromatic-primary-75",
    "dataviz_monochromatic-primary-100": "dataviz-monochromatic-primary-100",
    "dataviz_monochromatic-primary-200": "dataviz-monochromatic-primary-200",
    "dataviz_monochromatic-primary-300": "dataviz-monochromatic-primary-300",
    "dataviz_monochromatic-primary-400": "dataviz-monochromatic-primary-400",
    "dataviz_monochromatic-primary-500": "dataviz-monochromatic-primary-500",
    "dataviz_monochromatic-primary-600": "dataviz-monochromatic-primary-600",
    "dataviz_monochromatic-primary-700": "dataviz-monochromatic-primary-700",
    "dataviz_monochromatic-primary-800": "dataviz-monochromatic-primary-800",
    "dataviz_monochromatic-primary-900": "dataviz-monochromatic-primary-900",
    "dataviz_monochromatic-primary-25-hover": "dataviz-monochromatic-primary-25-hover",
    "dataviz_monochromatic-primary-50-hover": "dataviz-monochromatic-primary-50-hover",
    "dataviz_monochromatic-primary-75-hover": "dataviz-monochromatic-primary-75-hover",
    "dataviz_monochromatic-primary-100-hover": "dataviz-monochromatic-primary-100-hover",
    "dataviz_monochromatic-primary-200-hover": "dataviz-monochromatic-primary-200-hover",
    "dataviz_monochromatic-primary-300-hover": "dataviz-monochromatic-primary-300-hover",
    "dataviz_monochromatic-primary-400-hover": "dataviz-monochromatic-primary-400-hover",
    "dataviz_monochromatic-primary-500-hover": "dataviz-monochromatic-primary-500-hover",
    "dataviz_monochromatic-primary-600-hover": "dataviz-monochromatic-primary-600-hover",
    "dataviz_monochromatic-primary-700-hover": "dataviz-monochromatic-primary-700-hover",
    "dataviz_monochromatic-primary-800-hover": "dataviz-monochromatic-primary-800-hover",
    "dataviz_monochromatic-primary-900-hover": "dataviz-monochromatic-primary-900-hover",
    "dataviz_monochromatic-positive-25": "dataviz-monochromatic-positive-25",
    "dataviz_monochromatic-positive-50": "dataviz-monochromatic-positive-50",
    "dataviz_monochromatic-positive-75": "dataviz-monochromatic-positive-75",
    "dataviz_monochromatic-positive-100": "dataviz-monochromatic-positive-100",
    "dataviz_monochromatic-positive-200": "dataviz-monochromatic-positive-200",
    "dataviz_monochromatic-positive-300": "dataviz-monochromatic-positive-300",
    "dataviz_monochromatic-positive-400": "dataviz-monochromatic-positive-400",
    "dataviz_monochromatic-positive-500": "dataviz-monochromatic-positive-500",
    "dataviz_monochromatic-positive-600": "dataviz-monochromatic-positive-600",
    "dataviz_monochromatic-positive-700": "dataviz-monochromatic-positive-700",
    "dataviz_monochromatic-positive-800": "dataviz-monochromatic-positive-800",
    "dataviz_monochromatic-positive-900": "dataviz-monochromatic-positive-900",
    "dataviz_monochromatic-positive-25-hover": "dataviz-monochromatic-positive-25-hover",
    "dataviz_monochromatic-positive-50-hover": "dataviz-monochromatic-positive-50-hover",
    "dataviz_monochromatic-positive-75-hover": "dataviz-monochromatic-positive-75-hover",
    "dataviz_monochromatic-positive-100-hover": "dataviz-monochromatic-positive-100-hover",
    "dataviz_monochromatic-positive-200-hover": "dataviz-monochromatic-positive-200-hover",
    "dataviz_monochromatic-positive-300-hover": "dataviz-monochromatic-positive-300-hover",
    "dataviz_monochromatic-positive-400-hover": "dataviz-monochromatic-positive-400-hover",
    "dataviz_monochromatic-positive-500-hover": "dataviz-monochromatic-positive-500-hover",
    "dataviz_monochromatic-positive-600-hover": "dataviz-monochromatic-positive-600-hover",
    "dataviz_monochromatic-positive-700-hover": "dataviz-monochromatic-positive-700-hover",
    "dataviz_monochromatic-positive-800-hover": "dataviz-monochromatic-positive-800-hover",
    "dataviz_monochromatic-positive-900-hover": "dataviz-monochromatic-positive-900-hover",
    "dataviz_monochromatic-negative-25": "dataviz-monochromatic-negative-25",
    "dataviz_monochromatic-negative-50": "dataviz-monochromatic-negative-50",
    "dataviz_monochromatic-negative-75": "dataviz-monochromatic-negative-75",
    "dataviz_monochromatic-negative-100": "dataviz-monochromatic-negative-100",
    "dataviz_monochromatic-negative-200": "dataviz-monochromatic-negative-200",
    "dataviz_monochromatic-negative-300": "dataviz-monochromatic-negative-300",
    "dataviz_monochromatic-negative-400": "dataviz-monochromatic-negative-400",
    "dataviz_monochromatic-negative-500": "dataviz-monochromatic-negative-500",
    "dataviz_monochromatic-negative-600": "dataviz-monochromatic-negative-600",
    "dataviz_monochromatic-negative-700": "dataviz-monochromatic-negative-700",
    "dataviz_monochromatic-negative-800": "dataviz-monochromatic-negative-800",
    "dataviz_monochromatic-negative-900": "dataviz-monochromatic-negative-900",
    "dataviz_monochromatic-negative-25-hover": "dataviz-monochromatic-negative-25-hover",
    "dataviz_monochromatic-negative-50-hover": "dataviz-monochromatic-negative-50-hover",
    "dataviz_monochromatic-negative-75-hover": "dataviz-monochromatic-negative-75-hover",
    "dataviz_monochromatic-negative-100-hover": "dataviz-monochromatic-negative-100-hover",
    "dataviz_monochromatic-negative-200-hover": "dataviz-monochromatic-negative-200-hover",
    "dataviz_monochromatic-negative-300-hover": "dataviz-monochromatic-negative-300-hover",
    "dataviz_monochromatic-negative-400-hover": "dataviz-monochromatic-negative-400-hover",
    "dataviz_monochromatic-negative-500-hover": "dataviz-monochromatic-negative-500-hover",
    "dataviz_monochromatic-negative-600-hover": "dataviz-monochromatic-negative-600-hover",
    "dataviz_monochromatic-negative-700-hover": "dataviz-monochromatic-negative-700-hover",
    "dataviz_monochromatic-negative-800-hover": "dataviz-monochromatic-negative-800-hover",
    "dataviz_monochromatic-negative-900-hover": "dataviz-monochromatic-negative-900-hover",
    "dataviz_diverging-sequence-1-positive9": "dataviz-diverging-sequence-1-positive9",
    "dataviz_diverging-sequence-1-positive9-hover": "dataviz-diverging-sequence-1-positive9-hover",
    "dataviz_diverging-sequence-1-positive8": "dataviz-diverging-sequence-1-positive8",
    "dataviz_diverging-sequence-1-positive8-hover": "dataviz-diverging-sequence-1-positive8-hover",
    "dataviz_diverging-sequence-1-positive7": "dataviz-diverging-sequence-1-positive7",
    "dataviz_diverging-sequence-1-positive7-hover": "dataviz-diverging-sequence-1-positive7-hover",
    "dataviz_diverging-sequence-1-positive6": "dataviz-diverging-sequence-1-positive6",
    "dataviz_diverging-sequence-1-positive6-hover": "dataviz-diverging-sequence-1-positive6-hover",
    "dataviz_diverging-sequence-1-positive5": "dataviz-diverging-sequence-1-positive5",
    "dataviz_diverging-sequence-1-positive5-hover": "dataviz-diverging-sequence-1-positive5-hover",
    "dataviz_diverging-sequence-1-positive4": "dataviz-diverging-sequence-1-positive4",
    "dataviz_diverging-sequence-1-positive-4-hover": "dataviz-diverging-sequence-1-positive-4-hover",
    "dataviz_diverging-sequence-1-positive3": "dataviz-diverging-sequence-1-positive3",
    "dataviz_diverging-sequence-1-positive3-hover": "dataviz-diverging-sequence-1-positive3-hover",
    "dataviz_diverging-sequence-1-positive2": "dataviz-diverging-sequence-1-positive2",
    "dataviz_diverging-sequence-1-positive2-hover": "dataviz-diverging-sequence-1-positive2-hover",
    "dataviz_diverging-sequence-1-positive1": "dataviz-diverging-sequence-1-positive1",
    "dataviz_diverging-sequence-1-positive1-hover": "dataviz-diverging-sequence-1-positive1-hover",
    "dataviz_diverging-sequence-1-neutral": "dataviz-diverging-sequence-1-neutral",
    "dataviz_diverging-sequence-1-neutral-hover": "dataviz-diverging-sequence-1-neutral-hover",
    "dataviz_diverging-sequence-1-negative1": "dataviz-diverging-sequence-1-negative1",
    "dataviz_diverging-sequence-1-negative1-hover": "dataviz-diverging-sequence-1-negative1-hover",
    "dataviz_diverging-sequence-1-negative2": "dataviz-diverging-sequence-1-negative2",
    "dataviz_diverging-sequence-1-negative2-hover": "dataviz-diverging-sequence-1-negative2-hover",
    "dataviz_diverging-sequence-1-negative3": "dataviz-diverging-sequence-1-negative3",
    "dataviz_diverging-sequence-1-negative3-hover": "dataviz-diverging-sequence-1-negative3-hover",
    "dataviz_diverging-sequence-1-negative4": "dataviz-diverging-sequence-1-negative4",
    "dataviz_diverging-sequence-1-negative4-hover": "dataviz-diverging-sequence-1-negative4-hover",
    "dataviz_diverging-sequence-1-negative5": "dataviz-diverging-sequence-1-negative5",
    "dataviz_diverging-sequence-1-negative5-hover": "dataviz-diverging-sequence-1-negative5-hover",
    "dataviz_diverging-sequence-1-negative6": "dataviz-diverging-sequence-1-negative6",
    "dataviz_diverging-sequence-1-negative6-hover": "dataviz-diverging-sequence-1-negative6-hover",
    "dataviz_diverging-sequence-1-negative7": "dataviz-diverging-sequence-1-negative7",
    "dataviz_diverging-sequence-1-negative7-hover": "dataviz-diverging-sequence-1-negative7-hover",
    "dataviz_diverging-sequence-1-negative8": "dataviz-diverging-sequence-1-negative8",
    "dataviz_diverging-sequence-1-negative8-hover": "dataviz-diverging-sequence-1-negative8-hover",
    "dataviz_diverging-sequence-1-negative9": "dataviz-diverging-sequence-1-negative9",
    "dataviz_diverging-sequence-1-negative9-hover": "dataviz-diverging-sequence-1-negative9-hover",
    "dataviz_diverging-sequence-2-positive10": "dataviz-diverging-sequence-2-positive10",
    "dataviz_diverging-sequence-2-positive10-hover": "dataviz-diverging-sequence-2-positive10-hover",
    "dataviz_diverging-sequence-2-positive9": "dataviz-diverging-sequence-2-positive9",
    "dataviz_diverging-sequence-2-positive9-hover": "dataviz-diverging-sequence-2-positive9-hover",
    "dataviz_diverging-sequence-2-positive8": "dataviz-diverging-sequence-2-positive8",
    "dataviz_diverging-sequence-2-positive8-hover": "dataviz-diverging-sequence-2-positive8-hover",
    "dataviz_diverging-sequence-2-positive7": "dataviz-diverging-sequence-2-positive7",
    "dataviz_diverging-sequence-2-positive7-hover": "dataviz-diverging-sequence-2-positive7-hover",
    "dataviz_diverging-sequence-2-positive6": "dataviz-diverging-sequence-2-positive6",
    "dataviz_diverging-sequence-2-positive6-hover": "dataviz-diverging-sequence-2-positive6-hover",
    "dataviz_diverging-sequence-2-positive5": "dataviz-diverging-sequence-2-positive5",
    "dataviz_diverging-sequence-2-positive5-hover": "dataviz-diverging-sequence-2-positive5-hover",
    "dataviz_diverging-sequence-2-positive4": "dataviz-diverging-sequence-2-positive4",
    "dataviz_diverging-sequence-2-positive4-hover": "dataviz-diverging-sequence-2-positive4-hover",
    "dataviz_diverging-sequence-2-positive3": "dataviz-diverging-sequence-2-positive3",
    "dataviz_diverging-sequence-2-positive3-hover": "dataviz-diverging-sequence-2-positive3-hover",
    "dataviz_diverging-sequence-2-positive2": "dataviz-diverging-sequence-2-positive2",
    "dataviz_diverging-sequence-2-positive2-hover": "dataviz-diverging-sequence-2-positive2-hover",
    "dataviz_diverging-sequence-2-positive1": "dataviz-diverging-sequence-2-positive1",
    "dataviz_diverging-sequence-2-positive1-hover": "dataviz-diverging-sequence-2-positive1-hover",
    "dataviz_diverging-sequence-2-negative1": "dataviz-diverging-sequence-2-negative1",
    "dataviz_diverging-sequence-2-negative1-hover": "dataviz-diverging-sequence-2-negative1-hover",
    "dataviz_diverging-sequence-2-negative2": "dataviz-diverging-sequence-2-negative2",
    "dataviz_diverging-sequence-2-negative2-hover": "dataviz-diverging-sequence-2-negative2-hover",
    "dataviz_diverging-sequence-2-negative3": "dataviz-diverging-sequence-2-negative3",
    "dataviz_diverging-sequence-2-negative3-hover": "dataviz-diverging-sequence-2-negative3-hover",
    "dataviz_diverging-sequence-2-negative4": "dataviz-diverging-sequence-2-negative4",
    "dataviz_diverging-sequence-2-negative4-hover": "dataviz-diverging-sequence-2-negative4-hover",
    "dataviz_diverging-sequence-2-negative5": "dataviz-diverging-sequence-2-negative5",
    "dataviz_diverging-sequence-2-negative5-hover": "dataviz-diverging-sequence-2-negative5-hover",
    "dataviz_diverging-sequence-2-negative6": "dataviz-diverging-sequence-2-negative6",
    "dataviz_diverging-sequence-2-negative6-hover": "dataviz-diverging-sequence-2-negative6-hover",
    "dataviz_diverging-sequence-2-negative7": "dataviz-diverging-sequence-2-negative7",
    "dataviz_diverging-sequence-2-negative7-hover": "dataviz-diverging-sequence-2-negative7-hover",
    "dataviz_diverging-sequence-2-negative8": "dataviz-diverging-sequence-2-negative8",
    "dataviz_diverging-sequence-2-negative8-hover": "dataviz-diverging-sequence-2-negative8-hover",
    "dataviz_diverging-sequence-2-negative9": "dataviz-diverging-sequence-2-negative9",
    "dataviz_diverging-sequence-2-negative9-hover": "dataviz-diverging-sequence-2-negative9-hover",
    "dataviz_diverging-sequence-2-negative10": "dataviz-diverging-sequence-2-negative10",
    "dataviz_diverging-sequence-2-negative10-hover": "dataviz-diverging-sequence-2-negative10-hover",
    "dataviz_categorical-sequence-category1": "dataviz-categorical-sequence-category1",
    "dataviz_categorical-sequence-category1-hover": "dataviz-categorical-sequence-category1-hover",
    "dataviz_categorical-sequence-category2": "dataviz-categorical-sequence-category2",
    "dataviz_categorical-sequence-category2-hover": "dataviz-categorical-sequence-category2-hover",
    "dataviz_categorical-sequence-category3": "dataviz-categorical-sequence-category3",
    "dataviz_categorical-sequence-category3-hover": "dataviz-categorical-sequence-category3-hover",
    "dataviz_categorical-sequence-category4": "dataviz-categorical-sequence-category4",
    "dataviz_categorical-sequence-category4-hover": "dataviz-categorical-sequence-category4-hover",
    "dataviz_categorical-sequence-category5": "dataviz-categorical-sequence-category5",
    "dataviz_categorical-sequence-category5-hover": "dataviz-categorical-sequence-category5-hover",
    "dataviz_categorical-sequence-category6": "dataviz-categorical-sequence-category6",
    "dataviz_categorical-sequence-category6-hover": "dataviz-categorical-sequence-category6-hover",
    "dataviz_categorical-sequence-category7": "dataviz-categorical-sequence-category7",
    "dataviz_categorical-sequence-category7-hover": "dataviz-categorical-sequence-category7-hover",
    "dataviz_categorical-sequence-category8": "dataviz-categorical-sequence-category8",
    "dataviz_categorical-sequence-category8-hover": "dataviz-categorical-sequence-category8-hover",
    "dataviz_categorical-sequence-category9": "dataviz-categorical-sequence-category9",
    "dataviz_categorical-sequence-category9-hover": "dataviz-categorical-sequence-category9-hover",
    "dataviz_categorical-sequence-category10": "dataviz-categorical-sequence-category10",
    "dataviz_categorical-sequence-category10-hover": "dataviz-categorical-sequence-category10-hover",
    "dataviz_categorical-sequence-category11": "dataviz-categorical-sequence-category11",
    "dataviz_categorical-sequence-category11-hover": "dataviz-categorical-sequence-category11-hover",
    "dataviz_categorical-sequence-category12": "dataviz-categorical-sequence-category12",
    "dataviz_categorical-sequence-category12-hover": "dataviz-categorical-sequence-category12-hover",
    "dataviz_categorical-sequence-category13": "dataviz-categorical-sequence-category13",
    "dataviz_categorical-sequence-category13-hover": "dataviz-categorical-sequence-category13-hover",
    "dataviz_categorical-2colorgroup-option6-category2": "dataviz-categorical-2colorgroup-option6-category2",
    "dataviz_categorical-2colorgroup-option6-category1": "dataviz-categorical-2colorgroup-option6-category1",
    "dataviz_categorical-2colorgroup-option5-category2": "dataviz-categorical-2colorgroup-option5-category2",
    "dataviz_categorical-2colorgroup-option5-category1": "dataviz-categorical-2colorgroup-option5-category1",
    "dataviz_categorical-2colorgroup-option4-category2": "dataviz-categorical-2colorgroup-option4-category2",
    "dataviz_categorical-2colorgroup-option4-category1": "dataviz-categorical-2colorgroup-option4-category1",
    "dataviz_categorical-2colorgroup-option3-category2": "dataviz-categorical-2colorgroup-option3-category2",
    "dataviz_categorical-2colorgroup-option3-category1": "dataviz-categorical-2colorgroup-option3-category1",
    "dataviz_categorical-2colorgroup-option2-category2": "dataviz-categorical-2colorgroup-option2-category2",
    "dataviz_categorical-2colorgroup-option2-category1": "dataviz-categorical-2colorgroup-option2-category1",
    "dataviz_categorical-2colorgroup-option1-category2": "dataviz-categorical-2colorgroup-option1-category2",
    "dataviz_categorical-2colorgroup-option1-category1": "dataviz-categorical-2colorgroup-option1-category1",
    "dataviz_categorical-3colorgroup-option1-category1": "dataviz-categorical-3colorgroup-option1-category1",
    "dataviz_categorical-3colorgroup-option1-category2": "dataviz-categorical-3colorgroup-option1-category2",
    "dataviz_categorical-3colorgroup-option1-category3": "dataviz-categorical-3colorgroup-option1-category3",
    "dataviz_categorical-3colorgroup-option2-category1": "dataviz-categorical-3colorgroup-option2-category1",
    "dataviz_categorical-3colorgroup-option2-category2": "dataviz-categorical-3colorgroup-option2-category2",
    "dataviz_categorical-3colorgroup-option2-category3": "dataviz-categorical-3colorgroup-option2-category3",
    "dataviz_categorical-3colorgroup-option3-category1": "dataviz-categorical-3colorgroup-option3-category1",
    "dataviz_categorical-3colorgroup-option3-category2": "dataviz-categorical-3colorgroup-option3-category2",
    "dataviz_categorical-3colorgroup-option3-category3": "dataviz-categorical-3colorgroup-option3-category3",
    "dataviz_categorical-3colorgroup-option4-category1": "dataviz-categorical-3colorgroup-option4-category1",
    "dataviz_categorical-3colorgroup-option4-category2": "dataviz-categorical-3colorgroup-option4-category2",
    "dataviz_categorical-3colorgroup-option4-category3": "dataviz-categorical-3colorgroup-option4-category3",
    "dataviz_categorical-4colorgroup-option1-category1": "dataviz-categorical-4colorgroup-option1-category1",
    "dataviz_categorical-4colorgroup-option1-category2": "dataviz-categorical-4colorgroup-option1-category2",
    "dataviz_categorical-4colorgroup-option1-category3": "dataviz-categorical-4colorgroup-option1-category3",
    "dataviz_categorical-4colorgroup-option1-category4": "dataviz-categorical-4colorgroup-option1-category4",
    "dataviz_categorical-4colorgroup-option2-category1": "dataviz-categorical-4colorgroup-option2-category1",
    "dataviz_categorical-4colorgroup-option2-category2": "dataviz-categorical-4colorgroup-option2-category2",
    "dataviz_categorical-4colorgroup-option2-category3": "dataviz-categorical-4colorgroup-option2-category3",
    "dataviz_categorical-4colorgroup-option2-category4": "dataviz-categorical-4colorgroup-option2-category4",
    "dataviz_categorical-4colorgroup-option3-category1": "dataviz-categorical-4colorgroup-option3-category1",
    "dataviz_categorical-4colorgroup-option3-category2": "dataviz-categorical-4colorgroup-option3-category2",
    "dataviz_categorical-4colorgroup-option3-category3": "dataviz-categorical-4colorgroup-option3-category3",
    "dataviz_categorical-4colorgroup-option3-category4": "dataviz-categorical-4colorgroup-option3-category4",
    "dataviz_categorical-4colorgroup-option4-category1": "dataviz-categorical-4colorgroup-option4-category1",
    "dataviz_categorical-4colorgroup-option4-category2": "dataviz-categorical-4colorgroup-option4-category2",
    "dataviz_categorical-4colorgroup-option4-category3": "dataviz-categorical-4colorgroup-option4-category3",
    "dataviz_categorical-4colorgroup-option4-category4": "dataviz-categorical-4colorgroup-option4-category4",
    "dataviz_categorical-5colorgroup-option1-category1": "dataviz-categorical-5colorgroup-option1-category1",
    "dataviz_categorical-5colorgroup-option1-category2": "dataviz-categorical-5colorgroup-option1-category2",
    "dataviz_categorical-5colorgroup-option1-category3": "dataviz-categorical-5colorgroup-option1-category3",
    "dataviz_categorical-5colorgroup-option1-category4": "dataviz-categorical-5colorgroup-option1-category4",
    "dataviz_categorical-5colorgroup-option1-category5": "dataviz-categorical-5colorgroup-option1-category5",
    "dataviz_categorical-5colorgroup-option2-category1": "dataviz-categorical-5colorgroup-option2-category1",
    "dataviz_categorical-5colorgroup-option2-category2": "dataviz-categorical-5colorgroup-option2-category2",
    "dataviz_categorical-5colorgroup-option2-category3": "dataviz-categorical-5colorgroup-option2-category3",
    "dataviz_categorical-5colorgroup-option2-category4": "dataviz-categorical-5colorgroup-option2-category4",
    "dataviz_categorical-5colorgroup-option2-category5": "dataviz-categorical-5colorgroup-option2-category5",
    "dataviz_categorical-5colorgroup-option3-category1": "dataviz-categorical-5colorgroup-option3-category1",
    "dataviz_categorical-5colorgroup-option3-category2": "dataviz-categorical-5colorgroup-option3-category2",
    "dataviz_categorical-5colorgroup-option3-category3": "dataviz-categorical-5colorgroup-option3-category3",
    "dataviz_categorical-5colorgroup-option3-category4": "dataviz-categorical-5colorgroup-option3-category4",
    "dataviz_categorical-5colorgroup-option3-category5": "dataviz-categorical-5colorgroup-option3-category5",
    "dataviz_categorical-5colorgroup-option4-category1": "dataviz-categorical-5colorgroup-option4-category1",
    "dataviz_categorical-5colorgroup-option4-category2": "dataviz-categorical-5colorgroup-option4-category2",
    "dataviz_categorical-5colorgroup-option4-category3": "dataviz-categorical-5colorgroup-option4-category3",
    "dataviz_categorical-5colorgroup-option4-category4": "dataviz-categorical-5colorgroup-option4-category4",
    "dataviz_categorical-5colorgroup-option4-category5": "dataviz-categorical-5colorgroup-option4-category5",
    "dataviz_categorical-6colorgroup-option1-category1": "dataviz-categorical-6colorgroup-option1-category1",
    "dataviz_categorical-6colorgroup-option1-category2": "dataviz-categorical-6colorgroup-option1-category2",
    "dataviz_categorical-6colorgroup-option1-category3": "dataviz-categorical-6colorgroup-option1-category3",
    "dataviz_categorical-6colorgroup-option1-category4": "dataviz-categorical-6colorgroup-option1-category4",
    "dataviz_categorical-6colorgroup-option1-category5": "dataviz-categorical-6colorgroup-option1-category5",
    "dataviz_categorical-6colorgroup-option1-category6": "dataviz-categorical-6colorgroup-option1-category6",
    "dataviz_categorical-6colorgroup-option2-category1": "dataviz-categorical-6colorgroup-option2-category1",
    "dataviz_categorical-6colorgroup-option2-category2": "dataviz-categorical-6colorgroup-option2-category2",
    "dataviz_categorical-6colorgroup-option2-category3": "dataviz-categorical-6colorgroup-option2-category3",
    "dataviz_categorical-6colorgroup-option2-category4": "dataviz-categorical-6colorgroup-option2-category4",
    "dataviz_categorical-6colorgroup-option2-category5": "dataviz-categorical-6colorgroup-option2-category5",
    "dataviz_categorical-6colorgroup-option2-category6": "dataviz-categorical-6colorgroup-option2-category6",
    "dataviz_categorical-6colorgroup-option3-category1": "dataviz-categorical-6colorgroup-option3-category1",
    "dataviz_categorical-6colorgroup-option3-category2": "dataviz-categorical-6colorgroup-option3-category2",
    "dataviz_categorical-6colorgroup-option3-category3": "dataviz-categorical-6colorgroup-option3-category3",
    "dataviz_categorical-6colorgroup-option3-category4": "dataviz-categorical-6colorgroup-option3-category4",
    "dataviz_categorical-6colorgroup-option3-category5": "dataviz-categorical-6colorgroup-option3-category5",
    "dataviz_categorical-6colorgroup-option3-category6": "dataviz-categorical-6colorgroup-option3-category6",
    "dataviz_categorical-6colorgroup-option4-category1": "dataviz-categorical-6colorgroup-option4-category1",
    "dataviz_categorical-6colorgroup-option4-category2": "dataviz-categorical-6colorgroup-option4-category2",
    "dataviz_categorical-6colorgroup-option4-category3": "dataviz-categorical-6colorgroup-option4-category3",
    "dataviz_categorical-6colorgroup-option4-category4": "dataviz-categorical-6colorgroup-option4-category4",
    "dataviz_categorical-6colorgroup-option4-category5": "dataviz-categorical-6colorgroup-option4-category5",
    "dataviz_categorical-6colorgroup-option4-category6": "dataviz-categorical-6colorgroup-option4-category6",
    "dataviz_unavailable": "dataviz-unavailable",
    "dataviz_unavailable-weak": "dataviz-unavailable-weak",
    "dataviz_unavailable-strong": "dataviz-unavailable-strong",
    "dataviz_text-onlight": "dataviz-text-onlight",
    "dataviz_text-ondark": "dataviz-text-ondark"
};

export const Elevation = {
    "core_none": "shadow-none",
    "core_sm": "shadow-sm",
    "core_md": "shadow-md",
    "core_lg": "shadow-lg",
    "none": "elevation-none",
    "raised": "elevation-raised",
    "lifted": "elevation-lifted",
    "floating": "elevation-floating"
};

export const FontFamily = {
    "core_primary": "font-family-primary",
    "core_secondary": "font-family-secondary",
    "core_tertiary": "font-family-tertiary",
    "heading-3xl": "heading-3xl-font-family",
    "heading-2xl": "heading-2xl-font-family",
    "heading-xl": "heading-xl-font-family",
    "heading-lg": "heading-lg-font-family",
    "heading-md": "heading-md-font-family",
    "heading-sm": "heading-sm-font-family",
    "heading-xs": "heading-xs-font-family",
    "heading-xs-medium": "heading-xs-medium-font-family",
    "overline": "overline-font-family",
    "body-2xl": "body-2xl-font-family",
    "body-xl": "body-xl-font-family",
    "body-lg": "body-lg-font-family",
    "body-lg-medium": "body-lg-medium-font-family",
    "body-lg-semibold": "body-lg-semibold-font-family",
    "body-lg-bold": "body-lg-bold-font-family",
    "body-lg-underline": "body-lg-underline-font-family",
    "body-md": "body-md-font-family",
    "body-md-medium": "body-md-medium-font-family",
    "body-md-semibold": "body-md-semibold-font-family",
    "body-md-bold": "body-md-bold-font-family",
    "body-md-underline": "body-md-underline-font-family",
    "body-sm": "body-sm-font-family",
    "body-sm-medium": "body-sm-medium-font-family",
    "body-sm-semibold": "body-sm-semibold-font-family",
    "body-sm-bold": "body-sm-bold-font-family",
    "body-sm-underline": "body-sm-underline-font-family",
    "body-xs": "body-xs-font-family",
    "body-xs-medium": "body-xs-medium-font-family",
    "body-xs-semibold": "body-xs-semibold-font-family",
    "body-xs-bold": "body-xs-bold-font-family",
    "body-xs-underline": "body-xs-underline-font-family",
    "accent-lg": "accent-lg-font-family",
    "accent-sm": "accent-sm-font-family"
};

export const FontWeight = {
    "core_400": "font-weight-400",
    "core_410": "font-weight-410",
    "core_505": "font-weight-505",
    "core_580": "font-weight-580",
    "core_590": "font-weight-590",
    "core_680": "font-weight-680",
    "core_690": "font-weight-690",
    "heading-3xl": "heading-3xl-font-weight",
    "heading-2xl": "heading-2xl-font-weight",
    "heading-xl": "heading-xl-font-weight",
    "heading-lg": "heading-lg-font-weight",
    "heading-md": "heading-md-font-weight",
    "heading-sm": "heading-sm-font-weight",
    "heading-xs": "heading-xs-font-weight",
    "heading-xs-medium": "heading-xs-medium-font-weight",
    "overline": "overline-font-weight",
    "body-2xl": "body-2xl-font-weight",
    "body-xl": "body-xl-font-weight",
    "body-lg": "body-lg-font-weight",
    "body-lg-medium": "body-lg-medium-font-weight",
    "body-lg-semibold": "body-lg-semibold-font-weight",
    "body-lg-bold": "body-lg-bold-font-weight",
    "body-lg-underline": "body-lg-underline-font-weight",
    "body-md": "body-md-font-weight",
    "body-md-medium": "body-md-medium-font-weight",
    "body-md-semibold": "body-md-semibold-font-weight",
    "body-md-bold": "body-md-bold-font-weight",
    "body-md-underline": "body-md-underline-font-weight",
    "body-sm": "body-sm-font-weight",
    "body-sm-medium": "body-sm-medium-font-weight",
    "body-sm-semibold": "body-sm-semibold-font-weight",
    "body-sm-bold": "body-sm-bold-font-weight",
    "body-sm-underline": "body-sm-underline-font-weight",
    "body-xs": "body-xs-font-weight",
    "body-xs-medium": "body-xs-medium-font-weight",
    "body-xs-semibold": "body-xs-semibold-font-weight",
    "body-xs-bold": "body-xs-bold-font-weight",
    "body-xs-underline": "body-xs-underline-font-weight",
    "accent-lg": "accent-lg-font-weight",
    "accent-sm": "accent-sm-font-weight"
};

export const FontSize = {
    "core_120": "font-size-120",
    "core_140": "font-size-140",
    "core_160": "font-size-160",
    "core_180": "font-size-180",
    "core_200": "font-size-200",
    "core_240": "font-size-240",
    "core_280": "font-size-280",
    "core_320": "font-size-320",
    "core_360": "font-size-360",
    "core_480": "font-size-480",
    "heading-3xl": "heading-3xl-font-size",
    "heading-2xl": "heading-2xl-font-size",
    "heading-xl": "heading-xl-font-size",
    "heading-lg": "heading-lg-font-size",
    "heading-md": "heading-md-font-size",
    "heading-sm": "heading-sm-font-size",
    "heading-xs": "heading-xs-font-size",
    "heading-xs-medium": "heading-xs-medium-font-size",
    "overline": "overline-font-size",
    "body-2xl": "body-2xl-font-size",
    "body-xl": "body-xl-font-size",
    "body-lg": "body-lg-font-size",
    "body-lg-medium": "body-lg-medium-font-size",
    "body-lg-semibold": "body-lg-semibold-font-size",
    "body-lg-bold": "body-lg-bold-font-size",
    "body-lg-underline": "body-lg-underline-font-size",
    "body-md": "body-md-font-size",
    "body-md-medium": "body-md-medium-font-size",
    "body-md-semibold": "body-md-semibold-font-size",
    "body-md-bold": "body-md-bold-font-size",
    "body-md-underline": "body-md-underline-font-size",
    "body-sm": "body-sm-font-size",
    "body-sm-medium": "body-sm-medium-font-size",
    "body-sm-semibold": "body-sm-semibold-font-size",
    "body-sm-bold": "body-sm-bold-font-size",
    "body-sm-underline": "body-sm-underline-font-size",
    "body-xs": "body-xs-font-size",
    "body-xs-medium": "body-xs-medium-font-size",
    "body-xs-semibold": "body-xs-semibold-font-size",
    "body-xs-bold": "body-xs-bold-font-size",
    "body-xs-underline": "body-xs-underline-font-size",
    "accent-lg": "accent-lg-font-size",
    "accent-sm": "accent-sm-font-size"
};

export const LineHeight = {
    "core_1-125": "line-height-1-125",
    "core_1-14": "line-height-1-14",
    "core_1-20": "line-height-1-20",
    "core_1-25": "line-height-1-25",
    "core_1-33": "line-height-1-33",
    "core_1-4285": "line-height-1-4285",
    "core_1-50": "line-height-1-50",
    "heading-3xl": "heading-3xl-line-height",
    "heading-2xl": "heading-2xl-line-height",
    "heading-xl": "heading-xl-line-height",
    "heading-lg": "heading-lg-line-height",
    "heading-md": "heading-md-line-height",
    "heading-sm": "heading-sm-line-height",
    "heading-xs": "heading-xs-line-height",
    "heading-xs-medium": "heading-xs-medium-line-height",
    "overline": "overline-line-height",
    "body-2xl": "body-2xl-line-height",
    "body-xl": "body-xl-line-height",
    "body-lg": "body-lg-line-height",
    "body-lg-medium": "body-lg-medium-line-height",
    "body-lg-semibold": "body-lg-semibold-line-height",
    "body-lg-bold": "body-lg-bold-line-height",
    "body-lg-underline": "body-lg-underline-line-height",
    "body-md": "body-md-line-height",
    "body-md-medium": "body-md-medium-line-height",
    "body-md-semibold": "body-md-semibold-line-height",
    "body-md-bold": "body-md-bold-line-height",
    "body-md-underline": "body-md-underline-line-height",
    "body-sm": "body-sm-line-height",
    "body-sm-medium": "body-sm-medium-line-height",
    "body-sm-semibold": "body-sm-semibold-line-height",
    "body-sm-bold": "body-sm-bold-line-height",
    "body-sm-underline": "body-sm-underline-line-height",
    "body-xs": "body-xs-line-height",
    "body-xs-medium": "body-xs-medium-line-height",
    "body-xs-semibold": "body-xs-semibold-line-height",
    "body-xs-bold": "body-xs-bold-line-height",
    "body-xs-underline": "body-xs-underline-line-height",
    "accent-lg": "accent-lg-line-height",
    "accent-sm": "accent-sm-line-height"
};

export const Shape = {
    "core_0": "border-radius-0",
    "core_1": "border-radius-1",
    "core_2": "border-radius-2",
    "core_3": "border-radius-3",
    "core_4": "border-radius-4",
    "core_9999": "border-radius-9999",
    "circle": "shape-circle",
    "pill": "shape-pill",
    "rounded-lg": "shape-rounded-lg",
    "rounded-md": "shape-rounded-md",
    "rounded-sm": "shape-rounded-sm"
};

export const CoreSpace = {
    "core_0": "space-0",
    "core_10": "space-10",
    "core_20": "space-20",
    "core_40": "space-40",
    "core_80": "space-80",
    "core_160": "space-160",
    "core_240": "space-240",
    "core_320": "space-320",
    "core_400": "space-400",
    "core_480": "space-480",
    "core_640": "space-640",
    "core_800": "space-800",
    "core_960": "space-960",
    "core_1280": "space-1280"
};

export const SemanticSimplePaddingSpace = {
    "inset-xs": "space-inset-xs",
    "inset-sm": "space-inset-sm",
    "inset-md": "space-inset-md",
    "inset-lg": "space-inset-lg",
    "inset-xl": "space-inset-xl"
};

export const SemanticComplexPaddingSpace = {
    "inset-squish-sm": "space-inset-squish-sm",
    "inset-squish-md": "space-inset-squish-md",
    "inset-squish-lg": "space-inset-squish-lg",
    "inset-stretch-sm": "space-inset-stretch-sm",
    "inset-stretch-md": "space-inset-stretch-md",
    "inset-stretch-lg": "space-inset-stretch-lg"
};

export const SemanticSimpleMarginSpace = {
    "stack-xs": "space-stack-xs",
    "stack-sm": "space-stack-sm",
    "stack-md": "space-stack-md",
    "stack-lg": "space-stack-lg",
    "stack-xl": "space-stack-xl",
    "inline-xs": "space-inline-xs",
    "inline-sm": "space-inline-sm",
    "inline-md": "space-inline-md",
    "inline-lg": "space-inline-lg",
    "inline-xl": "space-inline-xl"
};

export const SemanticComplexMarginSpace = {

};

