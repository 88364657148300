/**
 * This file is generated by the generate-components script. Do not edit directly.
 */
/* eslint-disable */
import { createIcon } from "../createIcon.tsx";
import { forwardRef, type Ref, type SVGProps } from "react";

const PinIcon16 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => <svg width={16} height={16} viewBox="0 0 16 16" ref={ref} {...props}><path fillRule="evenodd" d="m6.853 6.748 3.156-3.156 2.388 2.388-3.156 3.156a.75.75 0 0 0-.213.636 3.32 3.32 0 0 1-.467 2.222L6.3 9.734 6.266 9.7 3.994 7.428a3.32 3.32 0 0 1 2.223-.468.75.75 0 0 0 .636-.212m-2.16 3.499L2.696 8.251c-.469-.468-.532-1.32.089-1.806a4.8 4.8 0 0 1 3.257-1.009l3.082-3.082a1.25 1.25 0 0 1 1.768 0l2.742 2.742a1.25 1.25 0 0 1 0 1.767l-3.083 3.083a4.8 4.8 0 0 1-1.009 3.257l-.535-.419.535.419c-.485.62-1.337.557-1.806.089l-1.984-1.984-2.435 2.435a.75.75 0 1 1-1.06-1.06z" /></svg>);
const PinIcon24 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => <svg width={24} height={24} viewBox="0 0 24 24" ref={ref} {...props}><path fillRule="evenodd" d="m10.315 10.062 4.46-4.46 3.607 3.608-4.46 4.46a.75.75 0 0 0-.212.636 4.75 4.75 0 0 1-.856 3.46L6.22 11.13a4.75 4.75 0 0 1 3.46-.855.75.75 0 0 0 .636-.213m5.344-5.697a1.25 1.25 0 0 0-1.768 0L9.509 8.747a6.24 6.24 0 0 0-4.504 1.428c-.585.488-.54 1.323-.065 1.797l3.014 3.014L4.47 18.47a.75.75 0 1 0 1.06 1.06l3.484-3.483 2.998 2.997c.474.475 1.309.52 1.797-.065a6.24 6.24 0 0 0 1.428-4.503l4.382-4.382a1.25 1.25 0 0 0 0-1.768z" /></svg>);
const PinIcon32 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => <svg width={32} height={32} viewBox="0 0 32 32" ref={ref} {...props}><path fillRule="evenodd" d="m13.944 13.698 5.478-5.479 4.371 4.372-5.478 5.478a1 1 0 0 0-.283.848 5.82 5.82 0 0 1-1 4.17L13 19.054l-.01-.011-.012-.01-4.052-4.053a5.82 5.82 0 0 1 4.17-1 1 1 0 0 0 .849-.283m-3.077 6.052-3.77-3.77c-.572-.572-.618-1.567.065-2.158a7.8 7.8 0 0 1 5.706-1.877l5.493-5.493a1.5 1.5 0 0 1 2.121 0l5.079 5.078a1.5 1.5 0 0 1 0 2.122l-5.494 5.493a7.8 7.8 0 0 1-1.877 5.705c-.591.683-1.586.637-2.158.065l-3.75-3.75-4.27 4.268A1 1 0 0 1 6.6 24.019z" /></svg>);

export const PinIcon = createIcon(PinIcon16, PinIcon24, PinIcon32, "PinIcon");